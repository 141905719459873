import Vue from 'vue'
import get from 'lodash/get'
import set from 'lodash/set'
import dot from 'dot-object'

Vue.mixin({
  methods: {
    $_get(obj, path, def = null) {
      return get(obj, path, def)
    },
    $_set(obj, path, val) {
      return set(obj, path, val)
    },
    $state(name) {
      const { $dirty, $error } = this.$_get(this.$v.form, name)
      return $dirty ? !$error : null
    },
    $hasServerErr(path) {
      return this.hasOwnProperty('serverErrors') && this.$_get(this.serverErrors, path) !== null
    },
    $_dot() {
      return dot
    },

  },
})
