import Vue from 'vue'

Vue.mixin({
  data() {
    return {
      loading_: false,
    }
  },
  computed: {
    $loading() {
      return this.loading_
    },
  },
  methods: {
    loadingOn() {
      this.loading_ = true
    },
    loadingOff() {
      this.loading_ = false
    },
  },
})
