// USA
export const locale = {
  BASE: {
    BROWSE: 'Browse',
    DROP_HERE_OR: 'Drop here or',
    TOTAL: 'Total',
    ERRORS: 'Errors',
    SEARCH: 'Search',
    PREVIEW: 'Preview',
    DELETE: 'Delete',
    NOT_SELECTED: 'Not Selected',
    BACK: 'Back',
    ACTIONS: 'Actions',
    YES: 'Yes',
    NO: 'No',
    LOADING_TABLE: 'Loading...',
    EMPTY_DATA: 'There are no records to show!',
    NO_DATA: 'No option to choose',
    CRITERIA: 'Filter',
    EDIT: 'Edit',
  },
  CURRENCY: {
    LIST_OF_CURRENCY: 'List of currencies',
    CURRENCY: 'Currency',
    SALE: 'Sale',
    BUY: 'Buy',
  },
  USER_PROFILE: {
    TITLE: 'User profile',
    HI: 'Hi',
  },
  CLIPBOARD: {
    COPY_SUCCESS: 'Copied',
  },
  TOAST: {
    CREATED: 'Item created',
    EDITED: 'Item edited',
    REMOVED: 'Item removed',
    ERROR: 'Error',
    SUCCESS: 'Success',
  },
  VALIDATION: {
    INVALID: '{name} is not valid',
    INVALID_TOKEN: 'Token is not valid',
    REQUIRED: '{name} is required',
    MIN_LENGTH: '{name} minimum length is {min}',
    AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
    NOT_FOUND: 'The requested {name} is not found',
    INVALID_LOGIN: 'The login detail is incorrect',
    REQUIRED_FIELD: 'Required field',
    MIN_LENGTH_FIELD: 'Minimum field length:',
    MAX_LENGTH_FIELD: 'Maximum field length:',
    INVALID_FIELD: 'Field is not valid',
    MAX_SIZE_FILE: 'Maximum upload file size: {size} Mb',
    MAX_COUNT_FILE: 'Only {count} docs/images are allowed',
    ORDER: {
      quantity: 'Quantity',
      service: 'Service',
      CANT_ADD_SERVICE_WITHOUT_PRICE: 'It isn\'t allowed to add services without current price',
      CANT_ADD_PRODUCT_WITHOUT_PRICE: 'It isn\'t allowed to add products without current price',
    },
  },
  DATES: {
    TODAY: 'Today',
    MONTH: 'Month',
    YEAR: 'Year',
    YESTERDAY: 'Yesterday',
    LAST_MONTH: 'Last month',
    DAYS_OF_WEEK: {
      SU: 'Sunday',
      MO: 'Monday',
      TU: 'Tuesday',
      WE: 'Wednesday',
      TH: 'Thursday',
      FR: 'Friday',
      SA: 'Saturday',
    },
    DAYS_OF_WEEK_ARRAY: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
  },
  FORM: {
    LOCALE: 'Locale',
    KEY: 'Key',
    TEXT: 'Text',
    MAILER_FROM_EMAIL: 'Email reference',
    PROLONGATE_SERVICE_PRICES_DAYS_BEFORE: 'Price renewal before X days',
    PROLONGATE_SERVICE_PRICES_DAYS_COUNT: 'Price renewal days count',
    PROLONGATE_SERVICE_PRICES_DAYS_BEFORE_HINT: 'How many days before the end to renew prices',
    PROLONGATE_SERVICE_PRICES_DAYS_COUNT_HINT: 'How many days to extend prices',
    ACTIVE_TELEGRAM_BOT: 'Active telegram bot',
    CURRENCY_PROVIDERS: 'Sources of exchange rates',
    TOKEN: 'Token',
    SUBMIT: 'Submit',
    CLEAR: 'Clear',
    LOGO: 'Logo',
    AVATAR: 'Avatar',
    NAME: 'Name',
    DOMAIN: 'Domain',
    PHONE: 'Phone',
    DESCRIPTION: 'Description',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    EMAIL: 'E-mail',
    ROLE: 'Role',
    COMPANY: 'Company',
    PASSWORD: 'Password',
    PARENT: 'Parent category',
    SERVICE: 'Service',
    COST: 'Cost',
    START_DATE: 'Start date',
    USERNAME: 'Username',
    END_DATE: 'End date',
    CATEGORY: 'Category',
    CLIENT_SEARCH: 'Client search',
    NEXT: 'Next',
    SAVE_AND_NEXT: 'Next step',
    SUPPLIER: 'Supplier',
    SKU: 'SKU',
    BRANCH: 'Branch',
    COMMENT: 'Comment',
    ACTIVE: 'Active',
    EMPLOYEES: 'Employees',
    PERMISSIONS: 'Permissions',
    GROUPS: 'Group Access',
    ENABLED: 'Enabled',
    CLIENTS: 'Clients',
    CREATORS: 'Creators',
    ADDRESS: 'Address',
    WORK_TIME_START: 'Start work time',
    WORK_TIME_END: 'End work time',
    NOTIFY: 'Notify when order...',
  },
  PLACEHOLDER: {
    USERNAME: 'Enter username',
    NAME: 'Enter name',
    DOMAIN: 'Enter domain',
    PHONE: 'Enter phone',
    FIRST_NAME: 'Enter first name',
    LAST_NAME: 'Enter last name',
    EMAIL: 'Enter E-mail',
    ROLE: 'Choose role',
    COMPANY: 'Choose company',
    PASSWORD: 'Enter password',
    DESCRIPTION: 'Enter description',
    SELECT: 'Select',
    COST: 'Enter cost',
    QUANTITY: 'Enter quantity',
    SKU: 'Enter SKU',
    COMMENT: 'Enter comment',
    ACTIVE: 'Active',
    VALUE: 'Enter value',
    CATEGORY: 'Category',
    ADDRESS: 'Enter address',
    WORK_TIME_START: 'Start work time',
    WORK_TIME_END: 'End work time',
    PERIODICITY: 'Enter periodicity',
    DISCOUNT: 'Enter discount',
  },
  MODAL: {
    QR_CODE: {
      TITLE: 'Link to the Telegram Bot',
      COPY_LINK: 'Copy link',
      FOOTER: 'Give this QR to the client to let him monitor order statuses and receive other updates via Telegram.',
      FOOTER_DANGER: 'Only for clients! Do not use this link even for testing.',
    },
    DELETE: {
      TITLE: 'Confirmation',
      DESC: 'Do you confirm the deletion?',
    },
  },
  COMPANY: {
    TITLE: 'Companies List',
    TITLE_NEW: 'Company New',
    TITLE_EDIT: 'Company Edit',
    RECEIPT_CONF: 'Template configuration',
    NEW: 'New',
    COMPANIES: 'Companies',
    COMPANY_FIRST_OWNER: 'First Owner',
    COMPANY_CREDENTIALS: 'Credentials',
  },
  COMPANY_USER: {
    TITLE: 'Users List',
    TITLE_NEW: 'User New',
    TITLE_EDIT: 'User Edit',
    NEW: 'New',
    USERS: 'Users',
    USER_CREDENTIALS: 'Credentials',
    ROLES: {
      ADMIN: 'Employee',
      ROLE_BUSINESS_OWNER: 'Owner',
      ROLE_BUSINESS_ADMIN: 'Employee',
    },
  },
  CLIENTS: {
    TITLE: 'Clients',
    TITLE_NEW: 'Client New',
    TITLE_EDIT: 'Client Edit',
    NAME: 'Name',
    NEW: 'New',
    CREDENTIALS: 'Credentials',
    CREATED: 'Created date',
  },
  EMPLOYEES: {
    TITLE: 'Employees',
    TITLE_NEW: 'Employee New',
    TITLE_EDIT: 'Employee Edit',
    NEW: 'New',
    USERS: 'Name and registration date',
    USER_CREDENTIALS: 'Employees credentials',
    ROLES: {
      ROLE_BUSINESS_ADMIN: 'Employee',
      ROLE_BUSINESS_OWNER: 'Owner',
    },
  },
  DASHBOARD: {
    LOGS_TITLE: 'Company Logs',
    PLAN_CARD: {
      TITLE: 'Plan implementation',
      SET_PLAN: 'Set daily plan',
      PLAN_VALUE: 'Plan value',
      OF: 'of',
    },
    ADMINS_STATISTICS: {
      TITLE: 'Employees statistics',
      PROFILE: 'Profile',
      ADMINS: 'Employees',
      SERVICES_AND_PRODUCTS: 'Services and products',
      ORDER_QTY: 'Orders QTY',
      ORDER_WITH_DISCOUNT: 'Orders final sum',
    },
    BRANCH_STATISTICS: {
      TITLE: 'Branch statistics',
      BRANCH: 'Branch',
      ITEMS_QTY: 'Items QTY',
      PREPAYMENTS: 'Prepayments',
      PAYMENTS: 'Payments',
      TOTAL: 'Total',
    },
  },
  SERVICES_LIST: {
    TITLE: 'Services',
    TITLE_NEW: 'Service New',
    TITLE_EDIT: 'Service Edit',
    FORM_PRICE_TITLE: 'Service price form',
    NEW: 'New',
    NAME: 'Name',
    CATEGORY: 'Category',
    TABLE_PRICE: 'Prices',
    COST: 'Cost',
    NO_PRICE: 'no price',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    PERIODICITY: 'Every',
    DISCOUNT_IS: 'Discount is',
    ITEM: 'service(s)',
    DISCOUNTS: 'Discounts',
    DISCOUNT_HINT: 'The client receives a discount for each service a multiple of.',
    PERCENT_HINT: 'Determines the size of the discount.',
    REMOVE_DISCOUNT: 'Remove discount',
  },
  STOCK_ENTRY: {
    TITLE: 'Remaining goods',
    PRODUCT: 'Good',
    QUANTITY: 'Quantity',
    WAREHOUSE: 'Warehouse',
    SOURCE: 'Source',
    NEW: 'Create',
    TITLE_NEW: 'Stock entry',
    TITLE_EDIT: 'Edit',
    RATE: 'Rate',
    PRODUCT_FROM: 'Source',
    SOURCE_SUPPLIER: 'Supplier',
    SOURCE_WAREHOUSE: 'Warehouse',
  },
  PRODUCT_LIST: {
    TITLE: 'Products',
    TITLE_NEW: 'Product New',
    TITLE_EDIT: 'Product Edit',
    FORM_PRICE_TITLE: 'Product price form',
    NEW: 'New',
    NAME: 'Name',
    CATEGORY: 'Category',
    TABLE_PRICE: 'Prices',
    COST: 'Cost',
    NO_PRICE: 'no price',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
  },
  SERVICES_CATEGORY: {
    TITLE: 'Service Categories',
    TITLE_NEW: 'Service Category New',
    TITLE_EDIT: 'Service Category Edit',
    NEW: 'New',
    NAME: 'Name',
    PARENT: 'Parent Category',
  },
  SERVICES_PRICES: {
    TITLE: 'Services Prices',
    TITLE_NEW: 'Services Price New',
    TITLE_EDIT: 'Services Price Edit',
    NEW: 'New',
    COST: 'Cost',
    SERVICE: 'Service',
    START_DATE: 'Start date',
    END_DATE: 'End date',
  },
  PRODUCTS_LIST: {
    TITLE: 'Products',
    TITLE_NEW: 'Product New',
    TITLE_EDIT: 'Product Edit',
    NEW: 'New',
    NAME: 'Name',
    SUPPLIER: 'Supplier',
    CATEGORY: 'Category',
    COST: 'Cost',
  },
  PRODUCTS_CATEGORY: {
    TITLE: 'Product Categories',
    NAME: 'Name',
    PARENT: 'Parent Category',
    NEW: 'New',
    TITLE_NEW: 'Product Category New',
    TITLE_EDIT: 'Product Category Edit',
  },
  PRODUCTS_SUPPLIER: {
    TITLE: 'Product Suppliers',
    NAME: 'Name',
    NEW: 'New',
    TITLE_NEW: 'Product Supplier New',
    TITLE_EDIT: 'Product Supplier Edit',
  },
  BRANCHES: {
    TITLE: 'Branches',
    NAME: 'Name',
    NEW: 'New',
    TITLE_NEW: 'Branch New',
    TITLE_EDIT: 'Branch Edit',
  },
  SETTINGS: {
    TITLE_EDIT: 'Edit settings',
  },
  TRANSLATIONS: {
    TITLE: 'Translations',
    KEY: 'Key',
    LOCALE: 'Locale',
    TEXT: 'Text',
    NEW: 'New',
    TITLE_NEW: 'Translation New',
    TITLE_EDIT: 'Translation Edit',
  },
  BOT: {
    TITLE: 'Bot',
    NAME: 'Name',
    TOKEN: 'Token',
    NEW: 'New',
    TITLE_NEW: 'Bot New',
    TITLE_EDIT: 'Bot Edit',
    INFO: 'This info is used in order receipts.',
    ACTIVE_BOT_MISSING_ERR: 'Active bot missing.',
    IN_PROGRESS: 'In progress',
    COMPLETE: 'Complete',
    CANCEL: 'Cancel',
    STATUS_DESCRIPTION: 'Choose order status when the bot should notify the client.',
    STATUS_PLACEHOLDER: 'Select status',
  },
  ORDERS: {
    TITLE: 'Orders',
    TITLE_NEW: 'Order Form',
    NEW: 'New',
    NUMBER: 'Order Number',
    PHONE: 'Phone',
    CLIENT_NAME: 'Client Name',
    TOTAL_COST: 'Total Cost',
    FINAL_COST: 'With discounts',
    STATUS: 'Status',
    PREPAYMENT: 'Prepayment',
    CLIENT: {
      TITLE: 'Define Order Client',
      CREATE_NEW_CLIENT: 'Create New Client',
    },
    ASIDE: {
      CLIENT: 'Client Definition',
      CLIENT_DESC: 'Enter Client Data',
      SETUP: 'Order Setup',
      SETUP_DESC: 'Complete Order Information',
      FILES: 'Files',
      FILES_DESC: 'Download Files',
      SUMMARY: 'Summary',
      SUMMARY_DESC: 'Verify Entered Data',
    },
    DEADLINE: 'Should be completed',
  },
  ORDER: {
    BACK_TO_LIST: 'Back To List',
    TITLE_NEW: 'Order New',
    TITLE_EDIT: 'Order Edit',
    START_DATE: 'Start Date',
    DUE_DATE: 'Due Date',
    STATUS: 'Status',
    ITEMS: 'Items',
    SERVICES: 'Services',
    PRODUCTS: 'Products',
    PREPAYMENT: 'Prepayment',
    RECEIPT: 'Get a receipt',
    PRINT_ALL: 'Print All',
    PRINT_MAIN: 'Print Main Receipt',
    PRINT_SECONDARY: 'Print Secondary Receipt',
    SETUP: {
      NAME: 'Name',
      COST: 'Cost',
      QUANTITY: 'Quantity',
      DISCOUNTS: 'Discounts',
      TOTAL_COST: 'Total Cost',
      TABLE_TITLE: 'Items List',
      ADD_SERVICE: 'Add Service',
      ADD_PRODUCT: 'Add Product',
      ITEMS: 'Items',
      ITEM: 'Item',
      OTHER_SERVICES_AND_PRODUCTS: 'Other Services And Products',
      ADD_ITEM: 'Add Item',
      DEFAULT_ITEM: 'Item 1',
      ITEM_EDIT: 'Item Edit',
      ITEM_NEW: 'Item New',
      TOTAL: 'Total',
      COST_WITH_DISCOUNT: 'With Discounts',
      SERVICES_LIST: 'Services List',
      PRODUCTS_LIST: 'Products List',
    },
    DISCOUNT: {
      FORM_TITLE: 'Discount setup',
      TYPE: 'Type',
      VALUE: 'Value',
      ABSOLUTE: 'Absolute',
      PERCENT: 'Percent',
    },
    FILES: {
      IMAGES: 'Images',
      DOCS: 'Docs',
      OTHER_FILES: 'Other Files',
    },
    STATUSES: {
      raw: 'New',
      in_progress: 'In Progress',
      cancel: 'Canceled',
      complete: 'Completed',
      archived: 'Archived',
    },
    PAYMENT_STATUSES: {
      paid: 'Paid',
      unpaid: 'Unpaid',
      paid_partially: 'Partially paid',
    },
    SUMMARY: {
      TITLE: 'Review Details and Submit',
      CLIENT_INFO: 'Client Info',
      NAME: 'Name',
      PHONE: 'Phone',
      ORDER_DETAILS: 'Order Details',
      SUMMARY: 'Summary',
      TOTAL_COST: 'Total Cost',
      FINAL_COST: 'With discounts',
      COST: 'Cost',
      DISCOUNTS: 'Discounts',
      QUANTITY: 'Quantity',
      ORDER_WAREHOUSE: 'Order Composition',
      COMMENT: 'Comment',
      ADD_DISCOUNT: 'Add discount',
      TOTAL: 'Total',
      ADD_PAYMENT: 'Add payment',
    },
    PAYMENTS: {
      TITLE: 'Payments',
      SUM: 'Sum',
      TYPE: 'Type',
      METHOD: 'Method',
      DATE: 'Date',
      TIME: 'Time',
      PAYMENT: 'Payment',
      PREPAYMENT: 'Prepayment',
      CASH: 'Cash',
      CARD: 'Bank card',
      FORM_TITLE: 'Payment setup',
    },
  },
  ROLES_EMPLOYEES: {
    TITLE: 'Groups',
    NEW: 'New',
    NAME: 'Name',
    TITLE_NEW: 'Group New',
    TITLE_EDIT: 'Group Edit',
  },
  REPORTS: {
    NO_DATA: 'No data found. Adjust the search settings or filters to refine the results.',
    TOTAL: 'Total',
    FILTERS: {
      START_DATE: 'Date from',
      END_DATE: 'Date to',
      BRANCHES: 'Branches',
      CLIENTS: 'Clients',
      CREATORS: 'Creators',
      SERVICES_CATEGORY: 'Service Categories',
      SERVICES: 'Services',
      ORDER_STATUS: 'Order statuses',
      PAYMENT_STATUS: 'Payment status',
      DATE_STEP: {
        TITLE: 'Range',
        day: 'By days',
        month: 'By months',
        year: 'By years',
      },
    },
    SERVICE_SUMS_BY_ORDERS: {
      TITLE: 'Sales Order Analysis (services)',
      DESCRIPTION: 'Order information: dates, customer, number of items and services. Costs include discounts.',
      FINAL_COST: 'Cost with discounts',
    },
    NUM_SERVICES_BY_CATEGORY: {
      TITLE: 'Sales Analytics (services)',
      DESCRIPTION: 'Services provided per a day, month or year. Costs include discounts.',
    },
    NUM_SERVICES_BY_BRANCH: {
      TITLE: 'Branches Analytics (services)',
      DESCRIPTION: 'Services provided by each branch. Costs include discounts.',
    },
    SUMS_BY_ORDERS: {
      TITLE: 'General sales report',
      DESCRIPTION: 'The cost of goods and services sold with and without discounts.',
    },
  },
  TRANSLATOR: {
    SELECT: 'Select your language',
  },
  DATEPICKER: {
    NO_DATE_SELECTED: 'No Date Selected',
  },
  PAGE: {
    TRANSLATIONS: 'Translations',
    COMMON: 'Common',
    SETTINGS: 'Settings',
    BOT: 'Bot',
    BOTS: 'Bots',
    ERROR: 'Error',
    LOGIN: 'Login',
    FORGOT_PASS: 'Forgot Password',
    RESET_PASS: 'Reset Password',
    CONFIRM_REGISTER: 'Confirm Register',
    REGISTER: 'Register',
    COMPANIES: 'Companies',
    COMPANIES_NEW: 'Company New',
    COMPANIES_EDIT: 'Company Edit',
    COMPANY_USERS: 'Users',
    COMPANY_USER_NEW: 'User New',
    COMPANY_USER_EDIT: 'User Edit',
    ECOMMERCE_SEPARATE: 'eCommerce',
    COMPANY_SEPARATE: 'COMPANY',
    STOCK_SEPARATE: 'STOCK',
    COMPANY_USER: 'Users',
    LAYOUT_BUILDER: 'Layout Builder',
    COMPANY: 'Company',
    EMPLOYEES: 'Employees',
    EMPLOYEES_NEW: 'Employee New',
    EMPLOYEES_EDIT: 'Employee Edit',
    DASHBOARD: 'Dashboard',
    ORDERS: 'Orders',
    ORDERS_NEW: 'Order New',
    ORDERS_EDIT: 'Order Edit',
    BRANCHES: 'Branches',
    BRANCHES_NEW: 'Branch New',
    BRANCHES_EDIT: 'Branch Edit',
    CLIENTS: 'Clients',
    CLIENTS_NEW: 'Client New',
    CLIENTS_EDIT: 'Client Edit',
    PRODUCTS_GROUP: 'Products',
    REPORTS_GROUP: 'Reports',
    PRODUCTS_LIST: 'Products List',
    PRODUCTS_LIST_NEW: 'Product New',
    PRODUCTS_LIST_EDIT: 'Product Edit',
    PRODUCTS_CATEGORIES: 'Product Categories',
    PRODUCTS_CATEGORIES_NEW: 'Product Category New',
    PRODUCTS_CATEGORIES_EDIT: 'Product Category Edit',
    PRODUCTS_SUPPLIER: 'Product Suppliers',
    PRODUCTS_SUPPLIER_NEW: 'Product Supplier New',
    PRODUCTS_SUPPLIER_EDIT: 'Product Supplier Edit',
    SERVICES_GROUP: 'Services',
    SERVICES_LIST: 'Services List',
    SERVICES_LIST_NEW: 'Service New',
    SERVICES_LIST_EDIT: 'Service Edit',
    SERVICES_CATEGORIES: 'Service Categories',
    SERVICES_CATEGORIES_NEW: 'Service Category New',
    SERVICES_CATEGORIES_EDIT: 'Service Category Edit',
    SERVICES_PRICES: 'Prices',
    SERVICES_PRICES_NEW: 'Services Price New',
    SERVICES_PRICES_EDIT: 'Services Price Edit',
    STAFF: 'Company Staff',
    ROLES_EMPLOYEES: 'Group Access',
    ROLES_EMPLOYEES_NEW: 'Group Access New',
    ROLES_EMPLOYEES_EDIT: 'Group Access Edit',
    TEMPLATES_NEW: 'New template',
    TEMPLATES: 'Templates',
    WAREHOUSES: 'Warehouses',
    SUPPLIERS: 'Suppliers',
    STOCK_ENTRY: 'Stock Entry',
    BOT_EDIT: 'Bot edit',
    BOT_NEW: 'Bot new',
  },
  AUTH: {
    SIGN_IN: 'Sign In',
    SUBMIT: 'Reset',
    FORGOT_PASS: 'Forgot Password?',
    FORGOT_PASS_DESC: 'Enter your email below to reset the password',
    GENERAL: {
      OR: 'Or',
      SUBMIT_BUTTON: 'Submit',
      NO_ACCOUNT: 'Don\'t have an account?',
      SIGNUP_BUTTON: 'Sign Up',
      FORGOT_BUTTON: 'Forgot Password',
      BACK_BUTTON: 'Back',
      PRIVACY: 'Privacy',
      LEGAL: 'Legal',
      CONTACT: 'Contact',
    },
    LOGIN: {
      TITLE: 'Login Account',
      DESC: 'Enter your username and password',
      BUTTON: 'Sign In',
    },
    LOGOUT: {
      BUTTON: 'Sign out',
    },
    RESET: {
      TITLE: 'Reset Password',
      DESC: 'Enter your new password',
      PASS_CHANGED: 'The password was changed successfully',
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your email to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
      REQUEST_SENT: 'Recovery link was sent on the email',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfuly registered.',
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{name} is required',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  TEMPLATES: {
    NEW: 'Create',
    NAME: 'Name',
    VARIABLES: 'Variables',
    TITLE_NEW: 'New template',
    ORDER_INVOICE_FOR_CUT: 'Secondary recipe template',
    ORDER_INVOICE: 'Main recipe template',
    PHONE_CONFIRMATION: 'Phone confirmation template',
    ORDER_STATUS_IN_PROGRESS: 'Order in progress template',
    ORDER_STATUS_COMPLETE: 'Order complete template',
    ORDER_STATUS_CANCEL: 'Order cancel template',
  },
  STOCK: {
    TITLE: 'Warehouses',
    TITLE_EDIT: 'Edit warehouse',
    TITLE_NEW: 'Create warehouse',
    DEFAULT: 'Default warehouse',
    NEW: 'New warehouse',
    PARENT: 'Parent warehouse name',
    NAME: 'NAME',
  },
}
