import Vue from 'vue'
import store from '@/core/services/store'
import i18n from '@/core/plugins/vue-i18n'
import PerfectScrollbar from 'perfect-scrollbar'
import ClipboardJS from 'clipboard'

import App from './App.vue'
import ApiService from './core/services/api.service'

import './directives/permission'
// Vue 3rd party plugins
import router from './router'
import './core/plugins'

import '@mdi/font/css/materialdesignicons.css'

import './helpers/filters'
import './registerServiceWorker'

Vue.config.productionTip = false
window.PerfectScrollbar = PerfectScrollbar
window.ClipboardJS = ClipboardJS

// API service init
ApiService.init()
Vue.http = ApiService
Vue.prototype.$http = ApiService

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
