const products = [
  {
    path: '/products',
    name: 'products',
    meta: { view: 'company' },
    component: () => import('@/view/pages/company/products'),
    children: [
      {
        path: '/products/list',
        name: 'productsListIndex',
        meta: {
          view: 'company',
          title: 'PRODUCTS_LIST',
          permission: ['view_products'], 
        },
        component: () => import('@/view/pages/company/products/list'),
      },
      {
        path: '/products/list/create',
        name: 'productsListCreate',
        meta: {
          view: 'company',
          title: 'PRODUCTS_LIST_NEW',
          permission: ['edit_products'], 
        },
        component: () => import('@/view/pages/company/products/list/new'),
      },
      {
        path: '/products/list/:id',
        name: 'productsListEdit',
        meta: {
          view: 'company',
          title: 'PRODUCTS_LIST_EDIT',
          permission: ['edit_products'], 
        },
        component: () => import('@/view/pages/company/products/list/id/edit'),
      },
      {
        path: '/products/categories',
        name: 'productsCategoriesIndex',
        meta: {
          view: 'company',
          title: 'PRODUCTS_CATEGORIES',
          permission: ['view_products'], 
        },
        component: () => import('@/view/pages/company/products/categories'),
      },
      {
        path: '/products/categories/create',
        name: 'productsCategoriesCreate',
        meta: {
          view: 'company',
          title: 'PRODUCTS_CATEGORIES_NEW',
          permission: ['edit_products'], 
        },
        component: () => import('@/view/pages/company/products/categories/new'),
      },
      {
        path: '/products/categories/:id',
        name: 'productsCategoriesEdit',
        meta: {
          view: 'company',
          title: 'PRODUCTS_CATEGORIES_EDIT',
          permission: ['edit_products'], 
        },
        component: () => import('@/view/pages/company/products/categories/id/edit'),
      },
      {
        path: '/products/supplier',
        name: 'productsSupplierIndex',
        meta: {
          view: 'company',
          title: 'PRODUCTS_SUPPLIER',
          permission: ['view_products'], 
        },
        component: () => import('@/view/pages/company/products/supplier'),
      },
      {
        path: '/products/supplier/create',
        name: 'productsSupplierCreate',
        meta: {
          view: 'company',
          title: 'PRODUCTS_SUPPLIER_NEW',
          permission: ['edit_products'], 
        },
        component: () => import('@/view/pages/company/products/supplier/new'),
      },
      {
        path: '/products/supplier/:id',
        name: 'productsSupplierEdit',
        meta: {
          view: 'company',
          title: 'PRODUCTS_SUPPLIER_EDIT',
          permission: ['edit_products'], 
        },
        component: () => import('@/view/pages/company/products/supplier/id/edit'),
      },
    ],
  },
]
export default products
