const suppliers = [
  {
    path: '/suppliers',
    name: 'companySuppliersIndex',
    meta: {
      view: 'company',
      title: 'SUPPLIERS',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/suppliers'),
  },
  {
    path: '/suppliers/create',
    name: 'companySuppliersCreate',
    meta: {
      view: 'company',
      title: 'SUPPLIERS_NEW',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/suppliers/new'),
  },
  {
    path: '/suppliers/:id',
    name: 'companySuppliersEdit',
    meta: {
      view: 'company',
      title: 'SUPPLIERS_EDIT',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/suppliers/id/edit'),
  },
]

export default suppliers
