const branches = [
  {
    path: '/branches',
    name: 'companyBranchesIndex',
    meta: {
      view: 'company',
      title: 'BRANCHES',
      permission: ['view_branches'], 
    },
    component: () => import('@/view/pages/company/branches'),
  },
  {
    path: '/branches/create',
    name: 'companyBranchesCreate',
    meta: {
      view: 'company',
      title: 'BRANCHES_NEW',
      permission: ['edit_branches'], 
    },
    component: () => import('@/view/pages/company/branches/new'),
  },
  {
    path: '/branches/:id',
    name: 'companyBranchesEdit',
    meta: {
      view: 'company',
      title: 'BRANCHES_EDIT',
      permission: ['edit_branches'], 
    },
    component: () => import('@/view/pages/company/branches/id/edit'),
  },
]
export default branches
