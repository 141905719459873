<!--suppress HtmlUnknownTarget -->
<template>
<!--suppress HtmlUnknownBooleanAttribute -->
<b-sidebar v-touch:swipe.right="onSwipe" :visible="value" :width="width" right no-header no-close-on-backdrop no-close-on-esc bg-variant="white"
           backdrop-variant="light-dark" shadow backdrop
>
  <div class="position-fixed w-100 bg-light zindex-1 py-3 px-3" style="height: 60px;">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="text-truncate my-0">
        <slot name="title" />
      </h5>
      <div class="d-flex align-items-center justify-content-end">
        <div class="btn btn-icon mx-2" @click="toggle">
          <span class="svg-icon svg-icon-secondary svg-icon-lg">
            <!--suppress HtmlUnknownTag -->
            <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
          </span>
        </div>
      </div>
    </div>
  </div>
  <perfect-scrollbar
    class="scroll px-3 py-5 mb-5" style="margin-top: 60px; height: calc(100% - 60px);"
  >
    <h6 v-if="!!$slots.header" class="text-center">
      <slot name="header" />
    </h6>
    <slot name="body" />
  </perfect-scrollbar>
</b-sidebar>
</template>
<script>
import { isMobile } from 'mobile-device-detect'
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from '@/core/services/store/htmlclass.module'
import {
  SAVE_SCROLL_POSITION,
  SCROLL_TO_POSITION,
} from '@/core/services/store/scroll.module'

export default {
  name: 'QuickPanel',
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      width: isMobile ? '100%' : '400px',
    }
  },
  watch: {
    value(val) {
      if (val) {
        document.addEventListener('keyup', this.onEscapeKeyUp)
        this.$store.dispatch(SAVE_SCROLL_POSITION)
        this.$store.dispatch(ADD_BODY_CLASSNAME, 'body-fixed')
      } else {
        document.removeEventListener('keyup', this.onEscapeKeyUp)
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'body-fixed')
        this.$store.dispatch(SCROLL_TO_POSITION)
      }
    },
  },
  mounted() {
    this.$on('toggle', this.toggle)
    this.$on('quick-panel-forward', (e) => {
      this.$emit('quick-panel-event', e)
    })
  },
  methods: {
    onSwipe() {
      this.setState(false)
    },
    onEscapeKeyUp(keyUp) {
      if (keyUp.key === 'Escape') {
        this.setState(false)
      }
    },
    toggle() {
      this.setState(!this.value)
    },
    setState(state) {
      this.$emit('input', state)
    },
  },
}
</script>

<style scoped>

</style>
