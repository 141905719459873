import objectPath from 'object-path'
import merge from 'lodash/merge'
import config from '@/core/config/layout.config.json'

// action types
export const SET_LAYOUT_CONFIG = 'setLayoutConfig'
export const RESET_LAYOUT_CONFIG = 'resetLayoutConfig'
export const OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig'
export const OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig'

// mutation types

export default {
  state: {
    config,
    initial: config,
    isQuickPanel: false,
    quickPanelName: '',
    quickPanelTitle: '',
    detectMobile: false,
    error: null,
  },
  getters: {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    layoutConfig: (state) => (path, defaultValue) => {
      return objectPath.get(state.config, path, defaultValue)
    },
    isMobile(state) {
      return state.detectMobile
    },
    getPanelName(state) {
      return state.quickPanelName
    },
    getPanelTitle(state) {
      return state.quickPanelTitle
    },
    isQuickPanel(state) {
      return state.isQuickPanel
    },
    getError(state) {
      return state.error
    },
  },
  actions: {
    /**
     * Set and replace the whole config
     * @param state
     * @param payload
     */
    [SET_LAYOUT_CONFIG](state, payload) {
      state.commit(SET_LAYOUT_CONFIG, payload)
    },

    /**
     * Reset the config at the initial state
     * @param state
     */
    [RESET_LAYOUT_CONFIG](state) {
      state.commit(RESET_LAYOUT_CONFIG)
    },

    /**
     * Reset the config using saved config in the cache by the layout builder
     * @param state
     */
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.commit(OVERRIDE_LAYOUT_CONFIG)
    },

    /**
     * Override config by page level
     * @param state
     * @param payload
     */
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload)
    },
  },
  mutations: {
    setErrorRequest(state, payload) {
      state.error = payload
    },
    setDetectMobile(state, payload) {
      state.detectMobile = payload < 992
    },
    setQuickPanelName(state, payload) {
      state.quickPanelName = payload
    },
    setQuickPanelTitle(state, payload) {
      state.quickPanelTitle = payload
    },
    onQuickPanelOpen(state) {
      state.isQuickPanel = true
    },
    onQuickPanelClose(state) {
      state.quickPanelName = ''
      state.quickPanelTitle = ''
      state.isQuickPanel = false
    },
    [SET_LAYOUT_CONFIG](state, payload) {
      state.config = payload
    },
    [RESET_LAYOUT_CONFIG](state) {
      state.config = { ...state.initial }
    },
    [OVERRIDE_LAYOUT_CONFIG](state) {
      const initialState = {
        ...state.initial,
        ...JSON.parse(localStorage.getItem('config')),
      }
      state.initial = initialState
      state.config = initialState
    },
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.config = merge(state.config, payload)
    },
  },
}
