import employees from './employees'
import services from './services'
import orders from './orders'
import branches from './branches'
import bot from './bot'
import products from './products'
import clients from './clients'
import roles from './roles'
import reports from './reports'
import templates from './templates'
import warehouses from './warehouses'
import suppliers from './suppliers'
import stockEntry from './stock-entry'
import settings from './settings'
import translations from './translations'

const routers = [
  {
    path: '/',
    name: 'dashboardCompany',
    meta: {
      view: 'company',
      title: 'DASHBOARD',
    },
    component: () => import('@/view/pages/company/Dashboard.vue'),
  },
  {
    path: '/builder',
    name: 'builderCompany',
    meta: { view: 'company' },
    component: () => import('@/view/pages/company/Builder.vue'),
  },
  ...employees,
  ...services,
  ...orders,
  ...reports,
  ...branches,
  ...bot,
  ...products,
  ...clients,
  ...templates,
  ...roles,
  ...warehouses,
  ...suppliers,
  ...stockEntry,
  ...settings,
  ...translations,
]
export default routers
