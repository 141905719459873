// UA
export const locale = {
  BASE: {
    BROWSE: 'оберіть з пристрою',
    DROP_HERE_OR: 'Перетягніть сюди або',
    TOTAL: 'загалом',
    ERRORS: 'Помилки',
    SEARCH: 'Пошук',
    PREVIEW: 'Переглянути',
    DELETE: 'Видалити',
    NOT_SELECTED: 'Not Selected',
    BACK: 'Назад',
    ACTIONS: 'Дії',
    YES: 'Так',
    NO: 'Ні',
    LOADING_TABLE: 'Завантаження...',
    EMPTY_DATA: 'Немає відповідних записів...',
    NO_DATA: 'Вибір відсутній',
    CRITERIA: 'Фільтр',
    EDIT: 'Редагувати',
  },
  USER_PROFILE: {
    TITLE: 'Профіль користувача',
    HI: 'Привіт',
  },
  CLIPBOARD: {
    COPY_SUCCESS: 'Скопійовано',
  },
  TOAST: {
    CREATED: 'Запис створено',
    EDITED: 'Запис відредаговано',
    ERROR: 'Помилка',
    SUCCESS: 'Успіх!',
  },
  VALIDATION: {
    INVALID: '{name} не є дійсним',
    REQUIRED: '{name} обов\'язкове поле',
    MIN_LENGTH: '{name} minimum length is {min}',
    AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
    NOT_FOUND: 'The requested {name} is not found',
    INVALID_LOGIN: 'The login detail is incorrect',
    REQUIRED_FIELD: 'Required field',
    MIN_LENGTH_FIELD: 'Minimum field length:',
    MAX_LENGTH_FIELD: 'Maximum field length:',
    INVALID_FIELD: 'Field is not valid',
    MAX_SIZE_FILE: 'Maximum upload file size: {size} Mb',
    MAX_COUNT_FILE: 'Only {count} docs/images are allowed',
    ORDER: {
      quantity: 'Кількість',
      service: 'Послуга',
      CANT_ADD_SERVICE_WITHOUT_PRICE: 'Обрано послугу без актуальної вартості',
      CANT_ADD_PRODUCT_WITHOUT_PRICE: 'Обрано продукт без актуальної вартості',
    },
  },
  DATES: {
    TODAY: 'Сьогодні',
    MONTH: 'Місяць',
    YEAR: 'Рік',
    YESTERDAY: 'Вчора',
    LAST_MONTH: 'Минулий місяць',
    DAYS_OF_WEEK: {
      SU: 'Неділя',
      MO: 'Понеділок',
      TU: 'Вівторок',
      WE: 'Середа',
      TH: 'Четвер',
      FR: 'Пятниця',
      SA: 'Субота',
    },
    DAYS_OF_WEEK_ARRAY: [
      'Понеділок',
      'Вівторок',
      'Середа',
      'Четвер',
      'Пятниця',
      'Субота',
      'Неділя',
    ],
  },
  FORM: {
    LOCALE: 'Локаль',
    KEY: 'Ключ',
    TEXT: 'Текст',
    MAILER_FROM_EMAIL: 'Email адреса',
    PROLONGATE_SERVICE_PRICES_DAYS_BEFORE: 'Продовження ціни за X днів',
    PROLONGATE_SERVICE_PRICES_DAYS_COUNT: 'Продовження ціни на X днів',
    PROLONGATE_SERVICE_PRICES_DAYS_BEFORE_HINT: 'За скільки днів до закінчення продовжувати ціни',
    PROLONGATE_SERVICE_PRICES_DAYS_COUNT_HINT: 'На скільки днів продовжувати ціни',
    ACTIVE_TELEGRAM_BOT: 'Активний телеграм бот',
    CURRENCY_PROVIDERS: 'Джерела курсів валют',
    TOKEN: 'Токен',
    SUBMIT: 'Підтвердити',
    CLEAR: 'Очистити',
    LOGO: 'Лого',
    AVATAR: 'Аватар',
    NAME: 'Ім\'я',
    DOMAIN: 'Домен',
    PHONE: 'Телефон',
    DESCRIPTION: 'Опис',
    FIRST_NAME: 'Ім\'я',
    LAST_NAME: 'Прізвище',
    EMAIL: 'E-mail',
    ROLE: 'Роль',
    COMPANY: 'Компанія',
    PASSWORD: 'Пароль',
    PARENT: 'Батьківська категорія',
    SERVICE: 'Послуга',
    COST: 'Вартість',
    START_DATE: 'Дата початку',
    USERNAME: 'Логін користувача',
    END_DATE: 'Дата завершення',
    CATEGORY: 'Категорія',
    CLIENT_SEARCH: 'Пошук по клієнтам',
    NEXT: 'Продовжити',
    SAVE_AND_NEXT: 'Наступний крок',
    SUPPLIER: 'Постачальник',
    SKU: 'SKU',
    BRANCH: 'Філія',
    COMMENT: 'Коментар',
    ACTIVE: 'Активність',
    EMPLOYEES: 'Співробітники',
    PERMISSIONS: 'Права',
    GROUPS: 'Група прав',
    ENABLED: 'Активність',
    CLIENTS: 'Клієнти',
    CREATORS: 'Творці',
    ADDRESS: 'Адреса',
    WORK_TIME_START: 'Початок роботи',
    WORK_TIME_END: 'Кінець роботи',
    NOTIFY: 'Повідомляти коли замовлення...',
  },
  PLACEHOLDER: {
    USERNAME: 'Введіть логін',
    NAME: 'Введіть назву',
    DOMAIN: 'Введіть домен',
    PHONE: 'Введіть телефон',
    FIRST_NAME: 'Введіть ім\'я',
    LAST_NAME: 'Введіть прізвище',
    EMAIL: 'Введіть E-mail',
    ROLE: 'Оберіть роль',
    COMPANY: 'Оберіть компанію',
    PASSWORD: 'Введіть пароль',
    DESCRIPTION: 'Введіть опис',
    SELECT: 'Оберіть',
    COST: 'Введіть вартість',
    QUANTITY: 'Введіть кількість',
    SKU: 'Введіть SKU',
    COMMENT: 'Введіть коментар',
    ACTIVE: 'Активність',
    VALUE: 'Введіть значення',
    CATEGORY: 'Категорія',
    ADDRESS: 'Введіть адресу',
    WORK_TIME_START: 'Початок роботи',
    WORK_TIME_END: 'Кінець роботи',
    PERIODICITY: 'Введіть періодичність',
    DISCOUNT: 'Введіть розмір знижки',
  },
  MODAL: {
    QR_CODE: {
      TITLE: 'Посилання на Telegram-бота',
      COPY_LINK: 'Копіювати посилання',
      FOOTER: 'Передайте цей QR клієнту для того, аби він мав можливість отримувати оновлення статусів замовлень та іншу інформацію.',
      FOOTER_DANGER: 'Тільки клієнтам! Не використовуйте це посилання навіть для тестування.',
    },
    DELETE: {
      TITLE: 'Підтвердження',
      DESC: 'Ви впевнені, що бажаєте видалити?',
    },
  },
  COMPANY: {
    TITLE: 'Список компаній',
    TITLE_NEW: 'Створення компанії',
    TITLE_EDIT: 'Редагування компанії',
    RECEIPT_CONF: 'Налаштування шаблону',
    NEW: 'Створення',
    COMPANIES: 'Компанії',
    COMPANY_FIRST_OWNER: 'Перший власник',
    COMPANY_CREDENTIALS: 'Реквізити',
  },
  COMPANY_USER: {
    TITLE: 'Користувачі',
    TITLE_NEW: 'Створення користувача',
    TITLE_EDIT: 'Редагування користувача',
    NEW: 'Створити',
    USERS: 'Користувачі',
    USER_CREDENTIALS: 'Реквізити',
    ROLES: {
      ROLE_BUSINESS_ADMIN: 'Співробітник компанії',
      ROLE_BUSINESS_OWNER: 'Власник компанії',
    },
  },
  CLIENTS: {
    TITLE: 'Клієнти',
    TITLE_NEW: 'Створення клієнта',
    TITLE_EDIT: 'Редагування клієнта',
    RECEIPT_CONF: 'Конфігурація квитанції',
    NAME: 'Ім\'я',
    NEW: 'Створити',
    CREDENTIALS: 'Реквізити',
    CREATED: 'Дата створення',
  },
  EMPLOYEES: {
    TITLE: 'Співробітники',
    TITLE_NEW: 'Створення співробітника',
    TITLE_EDIT: 'Редагування співробітника',
    NEW: 'Створити',
    USERS: 'Ім\'я та дата реєстрації',
    USER_CREDENTIALS: 'Реквізити',
    ROLES: {
      ROLE_BUSINESS_ADMIN: 'Співробітник компанії',
      ROLE_BUSINESS_OWNER: 'Власник компанії',
    },
  },
  DASHBOARD: {
    LOGS_TITLE: 'Реєстр дій компанії',
    PLAN_CARD: {
      TITLE: 'Виконання плану',
      SET_PLAN: 'Встановити щоденний план',
      PLAN_VALUE: 'Значення',
      OF: 'з',
    },
    ADMINS_STATISTICS: {
      TITLE: 'Статистика співробітників',
      PROFILE: 'Профіль',
      ADMINS: 'Співробітники',
      SERVICES_AND_PRODUCTS: 'Послуги та продукти',
      ORDER_QTY: 'Кількість замовлень',
      ORDER_WITH_DISCOUNT: 'Загальна сумма',
    },
    BRANCH_STATISTICS: {
      TITLE: 'Статистика філій',
      BRANCH: 'Філія',
      ITEMS_QTY: 'Кількість предметів',
      PREPAYMENTS: 'Передплати',
      PAYMENTS: 'Оплати',
      TOTAL: 'Загалом',
    },
  },
  SERVICES_LIST: {
    TITLE: 'Послуги',
    TITLE_NEW: 'Створення послуги',
    TITLE_EDIT: 'Редагування послуги',
    FORM_PRICE_TITLE: 'Вартість послуги',
    NEW: 'Створити',
    NAME: 'Назва',
    CATEGORY: 'Категорія',
    TABLE_PRICE: 'Історія вартості',
    COST: 'Вартість',
    NO_PRICE: 'вартість відсутня',
    START_DATE: 'Дата початку',
    END_DATE: 'Дата завершення',
    PERIODICITY: 'Кожну',
    DISCOUNT_IS: 'Знижка',
    ITEM: 'послугу',
    DISCOUNTS: 'Знижки',
    DISCOUNT_HINT: 'Клієнт отримує знижку на кожну послугу кратну числу.',
    PERCENT_HINT: 'Визначає розмір знижки.',
    REMOVE_DISCOUNT: 'Видалити знижку',
  },
  STOCK_ENTRY: {
    TITLE: 'Залишки товарів',
    PRODUCT: 'Товар',
    QUANTITY: 'Кількість',
    WAREHOUSE: 'Склад',
    SOURCE: 'Джерело',
    NEW: 'Створити',
    TITLE_NEW: 'Заведення товару',
    TITLE_EDIT: 'Редагування',
    RATE: 'Ціна',
    PRODUCT_FROM: 'Джерело',
    SOURCE_SUPPLIER: 'Постачальник',
    SOURCE_WAREHOUSE: 'Склад',
  },
  PRODUCT_LIST: {
    TITLE: 'Товари',
    TITLE_NEW: 'Створення товару',
    TITLE_EDIT: 'Редагування товару',
    FORM_PRICE_TITLE: 'Вартість товару',
    NEW: 'Створити',
    NAME: 'Назва',
    CATEGORY: 'Категорія',
    TABLE_PRICE: 'Історія вартості',
    COST: 'Вартість',
    NO_PRICE: 'вартість відсутня',
    START_DATE: 'Дата початку',
    END_DATE: 'Дата завершення',
  },
  SERVICES_CATEGORY: {
    TITLE: 'Категорії послуг',
    TITLE_NEW: 'Створення категорії',
    TITLE_EDIT: 'Редагування категорії',
    NEW: 'Створити',
    NAME: 'Назва',
    PARENT: 'Батьківська категорія',
  },
  SERVICES_PRICES: {
    TITLE: 'Services Prices',
    TITLE_NEW: 'Services Price New',
    TITLE_EDIT: 'Services Price Edit',
    NEW: 'New',
    COST: 'Вартість',
    SERVICE: 'Послуга',
    START_DATE: 'Дата початку',
    END_DATE: 'Дата завершення',
  },
  PRODUCTS_LIST: {
    TITLE: 'Товари',
    TITLE_NEW: 'Створення товару',
    TITLE_EDIT: 'Редагування товару',
    NEW: 'Створити',
    NAME: 'Назва',
    SUPPLIER: 'Постачальник',
    CATEGORY: 'Категорія',
  },
  PRODUCTS_CATEGORY: {
    TITLE: 'Категорії продуктів',
    NAME: 'Назва',
    PARENT: 'Батьківська категорія',
    NEW: 'Створити',
    TITLE_NEW: 'Створення категорії',
    TITLE_EDIT: 'Редагування категорії',
  },
  PRODUCTS_SUPPLIER: {
    TITLE: 'Постачальники товарів',
    NAME: 'Назва',
    NEW: 'Створити',
    TITLE_NEW: 'Створення постачальника',
    TITLE_EDIT: 'Редагування постачальника',
  },
  BRANCHES: {
    TITLE: 'Філії',
    NAME: 'Назва',
    NEW: 'Створити',
    TITLE_NEW: 'Створення філії',
    TITLE_EDIT: 'Редагування філії',
    INFO: 'Дана інформація використовується у квитанціях.',
  },
  SETTINGS: {
    TITLE_EDIT: 'Змінити налаштування',
  },
  TRANSLATIONS: {
    TITLE: 'Переклади',
    KEY: 'Ключ',
    LOCALE: 'Локаль',
    TEXT: 'Текст',
    NEW: 'Створити',
    TITLE_NEW: 'Створення перекладу',
    TITLE_EDIT: 'Редагування перекладу',
  },
  BOT: {
    TOKEN: 'Токен',
    TITLE: 'Бот',
    NAME: 'Назва',
    NEW: 'Створити',
    TITLE_NEW: 'Створення боту',
    TITLE_EDIT: 'Редагування боту',
    ACTIVE_BOT_MISSING_ERR: 'Відсутній активний бот.',
    IN_PROGRESS: 'В процесі',
    COMPLETE: 'Готово',
    CANCEL: 'Скасовано',
    STATUS_DESCRIPTION: 'Обери статус, при якому бот буде повідомляти клієнта.',
    STATUS_PLACEHOLDER: 'Обери статус',
  },
  ORDERS: {
    TITLE: 'Замовлення',
    TITLE_NEW: 'Форма замовлення',
    NEW: 'Створити',
    NUMBER: 'Номер замовлення',
    PHONE: 'Телефон',
    CLIENT_NAME: 'Ім\'я клієнта',
    TOTAL_COST: 'Сума',
    FINAL_COST: 'Зі знижками',
    STATUS: 'Статус',
    PREPAYMENT: 'Cплачено',
    CLIENT: {
      TITLE: 'Визначення клієнта замовлення',
      CREATE_NEW_CLIENT: 'Створити клієнта',
    },
    ASIDE: {
      CLIENT: 'Визначення клієнта',
      CLIENT_DESC: 'Внесення інформації про клієнта',
      SETUP: 'Формування замовлення',
      SETUP_DESC: 'Визначення позицій замовлення',
      FILES: 'Файли',
      FILES_DESC: 'Завантаження файлів',
      SUMMARY: 'Підсумок замовлення',
      SUMMARY_DESC: 'Перевірка введеної інформації',
    },
    DEADLINE: 'Дата готовності',
  },
  ORDER: {
    BACK_TO_LIST: 'Повернутися',
    TITLE_NEW: 'Створення замовлення',
    TITLE_EDIT: 'Редагування замовлення',
    START_DATE: 'Дата створення',
    DUE_DATE: 'Завершити',
    ITEMS: 'Предметів',
    SERVICES: 'Послуг',
    PRODUCTS: 'Товарів',
    PREPAYMENT: 'Сплачено',
    RECEIPT: 'Квитанція',
    PRINT_ALL: 'Друкувати обидві',
    PRINT_MAIN: 'Друкувати основну',
    PRINT_SECONDARY: 'Друкувати вторинну',
    SETUP: {
      NAME: 'Назва',
      COST: 'Сума',
      QUANTITY: 'Кількість',
      DISCOUNTS: 'Знижки',
      TOTAL_COST: 'Загальна сума',
      TABLE_TITLE: 'Позиції замовлення',
      ADD_SERVICE: 'Додати послугу',
      ADD_PRODUCT: 'Додати продукт',
      ITEMS: 'Предмети замовлення',
      ITEM: 'Предмет замовлення',
      OTHER_SERVICES_AND_PRODUCTS: 'Інші послуги та продукти',
      ADD_ITEM: 'Додати предмет',
      DEFAULT_ITEM: 'Предмет 1',
      ITEM_EDIT: 'Редагування предмету',
      ITEM_NEW: 'Додавання предмету',
      TOTAL: 'Сума',
      COST_WITH_DISCOUNT: 'Зі знижками',
      SERVICES_LIST: 'Список послуг',
      PRODUCTS_LIST: 'Список товарів',
    },
    DISCOUNT: {
      FORM_TITLE: 'Налаштування знижки',
      TYPE: 'Тип',
      VALUE: 'Значення',
      ABSOLUTE: 'Абсолютна',
      PERCENT: 'Відсоток',
    },
    FILES: {
      IMAGES: 'Зображення',
      DOCS: 'Документи',
    },
    STATUSES: {
      raw: 'Новий',
      in_progress: 'В роботі',
      cancel: 'Скасований',
      complete: 'Завершений',
      archived: 'Архівований',
    },
    PAYMENT_STATUSES: {
      paid: 'Сплачено',
      unpaid: 'Не сплачено',
      paid_partially: 'Сплачено частково',
    },
    SUMMARY: {
      TITLE: 'Перегляд і підтвердження замовлення',
      CLIENT_INFO: 'Клієнт',
      NAME: 'Ім\'я',
      PHONE: 'Телефон',
      ORDER_DETAILS: 'Склад замовлення',
      SUMMARY: 'Summary',
      TOTAL_COST: 'Сума',
      FINAL_COST: 'Зі знижками',
      COST: 'Сума',
      DISCOUNTS: 'Знижки',
      QUANTITY: 'Кількість',
      ORDER_WAREHOUSE: 'Склад замовлення',
      COMMENT: 'Коментарій',
      ADD_DISCOUNT: 'Додати знижку',
      ADD_PAYMENT: 'Додати оплату',
    },
    PAYMENTS: {
      TITLE: 'Оплати',
      SUM: 'Сума',
      TYPE: 'Тип',
      METHOD: 'Метод',
      DATE: 'Дата',
      TIME: 'Час',
      PAYMENT: 'Оплата',
      PREPAYMENT: 'Передплата',
      CASH: 'Готівка',
      CARD: 'Оплата картою',
      FORM_TITLE: 'Оплата',
    },
  },
  ROLES_EMPLOYEES: {
    TITLE: 'Групи доступу',
    NEW: 'Створити',
    NAME: 'Назва групи',
    TITLE_NEW: 'Створити групу',
    TITLE_EDIT: 'Редагувати групу',
  },
  REPORTS: {
    NO_DATA: 'Дані не знайдено. Налаштуйте фільтри, щоб уточнити результати звіту.',
    TOTAL: 'Загалом',
    FILTERS: {
      START_DATE: 'Дата від',
      END_DATE: 'Дата до',
      BRANCHES: 'Філії',
      CLIENTS: 'Клієнти',
      CREATORS: 'Творці',
      SERVICES_CATEGORY: 'Категорії послуг',
      SERVICES: 'Послуги',
      ORDER_STATUS: 'Статуси замовлення',
      PAYMENT_STATUS: 'Статуси оплати',
      DATE_STEP: {
        TITLE: 'Період',
        day: 'По дням',
        month: 'По місяцям',
        year: 'По рокам',
      },
    },
    SERVICE_SUMS_BY_ORDERS: {
      TITLE: 'Звіт послуг по замовленням',
      DESCRIPTION: 'Інформація про замовлення: дати, клієнт, кількість предметів та послуг. Вартості вказані з урахуванням знижок.',
      FINAL_COST: 'Сума зі знижками',
    },
    NUM_SERVICES_BY_CATEGORY: {
      TITLE: 'Аналітика продажів (послуги)',
      DESCRIPTION: 'Звіт за наданими послугами за день, місяць або рік. Вартості вказані з урахуванням знижок.',
    },
    NUM_SERVICES_BY_BRANCH: {
      TITLE: 'Аналітика по філіям (послуги)',
      DESCRIPTION: 'Звіт по послугах, наданих по кожній філії. Вартості вказані з урахуванням знижок.',
    },
    SUMS_BY_ORDERS: {
      TITLE: 'Загальний звіт по продажам',
      DESCRIPTION: 'Вартість та кількість реалізованих товарів та послуг. Вартості вказані з та без урахування знижок.',
    },
  },
  TRANSLATOR: {
    SELECT: 'Оберіть мову інтерфейсу',
  },
  DATEPICKER: {
    NO_DATE_SELECTED: 'Дата не вибрана',
  },
  PAGE: {
    TRANSLATIONS: 'Переклади',
    COMMON: 'Загальні',
    SETTINGS: 'Налаштування',
    BOT: 'Бот',
    BOTS: 'Боти',
    COMPANIES: 'Компанії',
    COMPANY_USERS: 'Користувачі',
    ECOMMERCE_SEPARATE: 'Комерція',
    COMPANY_SEPARATE: 'Компанія',
    STOCK_SEPARATE: 'Склад',
    COMPANY_USER: 'Користувачі',
    LAYOUT_BUILDER: 'Конструктор інтерфейсу',
    COMPANY: 'Компанія',
    EMPLOYEES: 'Співробітники',
    DASHBOARD: 'Панель показників',
    ORDERS: 'Замовлення',
    BRANCHES: 'Філії',
    CLIENTS: 'Клієнти',
    PRODUCTS_GROUP: 'Товари',
    REPORTS_GROUP: 'Звіти',
    PRODUCTS_LIST: 'Список товарів',
    PRODUCTS_CATEGORIES: 'Категорії товарів',
    PRODUCTS_SUPPLIER: 'Постачальники товарів',
    SERVICES_GROUP: 'Послуги',
    SERVICES_LIST: 'Список послуг',
    SERVICES_CATEGORIES: 'Категорії послуг',
    SERVICES_PRICES: 'Вартість',
    SERVICES_PRICES_EDIT: 'Редагування вартості',
    STAFF: 'Штат компанії',
    ROLES_EMPLOYEES: 'Групи прав',
    ROLES_EMPLOYEES_NEW: 'Створення групи прав',
    ROLES_EMPLOYEES_EDIT: 'Редагування групи прав',
    TEMPLATES_NEW: 'Новий шаблон',
    TEMPLATES: 'Шаблони',
    WAREHOUSES: 'Склади',
    SUPPLIERS: 'Постачальники',
    STOCK_ENTRY: 'Введення запасів',
    BOT_EDIT: 'Редагування боту',
    BOT_NEW: 'Новий бот',
  },
  AUTH: {
    SIGN_IN: 'Вхід',
    SUBMIT: 'Підтвердити',
    FORGOT_PASS: 'Забули пароль ?',
    FORGOT_PASS_DESC: 'Введіть свій E-mail нижче для оновлення паролю',
    GENERAL: {
      OR: 'Or',
      SUBMIT_BUTTON: 'Submit',
      NO_ACCOUNT: 'Don\'t have an account?',
      SIGNUP_BUTTON: 'Sign Up',
      FORGOT_BUTTON: 'Forgot Password',
      BACK_BUTTON: 'Back',
      PRIVACY: 'Privacy',
      LEGAL: 'Legal',
      CONTACT: 'Contact',
    },
    LOGIN: {
      TITLE: 'Login Account',
      DESC: 'Введіть ім\'я користувача і пароль',
      BUTTON: 'Sign In',
    },
    LOGOUT: {
      BUTTON: 'Вихід',
    },
    RESET: {
      TITLE: 'Скидання паролю',
      DESC: 'Введіть свій новий пароль',
      PASS_CHANGED: 'Пароль успішно змінено',
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your E-mail to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
      REQUEST_SENT: 'Посилання на відновлення паролю було відправлено на E-mail',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfully registered.',
    },
    VALIDATION: {
      INVALID: '{{name}} не є дійсним',
      REQUIRED: '{name} обов\'язкове поле',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  TEMPLATES: {
    NEW: 'Створити',
    NAME: 'Назва',
    VARIABLES: 'Значення',
    TITLE_NEW: 'Новий шаблон',
    ORDER_INVOICE_FOR_CUT: 'Шаблон вторинної квитанції',
    ORDER_INVOICE: 'Шаблон основної квитанції',
    PHONE_CONFIRMATION: 'Шаблон підтвердження телефону',
    ORDER_STATUS_IN_PROGRESS: 'Шаблон "Замовлення в роботі"',
    ORDER_STATUS_COMPLETE: 'Шаблон "Замовлення виконано"',
    ORDER_STATUS_CANCEL: 'Шаблон "Замовлення скасовано"',
  },
  STOCK: {
    TITLE: 'Склади',
    TITLE_EDIT: 'Редагування складу',
    TITLE_NEW: 'Створення складу',
    DEFAULT: 'Склад за замовчуванням',
    NEW: 'Новий склад',
    PARENT: 'Назва parent складу',
    NAME: 'Назва',
  },
}
