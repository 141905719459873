export default [
  {
    path: '/error',
    name: 'error',
    component: () => import('@/view/pages/error/Error.vue'),
    children: [
      {
        path: 'error-403',
        name: 'error-403',
        component: () => import('@/view/pages/error/Error-403.vue'),
      },
      {
        path: 'error-404',
        name: 'error-404',
        component: () => import('@/view/pages/error/Error-404.vue'),
      },
    ],
  },
]
