// RU
export const locale = {
  BASE: {
    BROWSE: 'выберите на устройстве',
    DROP_HERE_OR: 'Перетащите сюда или',
    TOTAL: 'всего',
    ERRORS: 'Ошибки',
    SEARCH: 'Поиск',
    PREVIEW: 'Просмотреть',
    DELETE: 'Удалить',
    NOT_SELECTED: 'Not Selected',
    BACK: 'Назад',
    ACTIONS: 'Действия',
    YES: 'Да',
    NO: 'Нет',
    LOADING_TABLE: 'Загрузка...',
    EMPTY_DATA: 'Нет соответствующих записей...',
    NO_DATA: 'Выбор отсутствует',
    CRITERIA: 'Фильтр',
    EDIT: 'Редактировать',
  },
  USER_PROFILE: {
    TITLE: 'Профиль пользователя',
    HI: 'Привет',
  },
  CLIPBOARD: {
    COPY_SUCCESS: 'Скопировано',
  },
  TOAST: {
    CREATED: 'Запись создана',
    EDITED: 'Запись отредактирована',
    ERROR: 'Ошибка',
    SUCCESS: 'Отлично!',
  },
  VALIDATION: {
    INVALID: '{name} не является допустимым',
    REQUIRED: '{name} обязательное поле',
    MIN_LENGTH: '{name} minimum length is {min}',
    AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
    NOT_FOUND: 'The requested {name} is not found',
    INVALID_LOGIN: 'The login detail is incorrect',
    REQUIRED_FIELD: 'Required field',
    MIN_LENGTH_FIELD: 'Minimum field length:',
    MAX_LENGTH_FIELD: 'Maximum field length:',
    INVALID_FIELD: 'Field is not valid',
    MAX_SIZE_FILE: 'Maximum upload file size: {size} Mb',
    MAX_COUNT_FILE: 'Only {count} docs/images are allowed',
    ORDER: {
      quantity: 'Количество',
      service: 'Услуга',
      CANT_ADD_SERVICE_WITHOUT_PRICE: 'Выбрана услуга без актуальной стоимости',
      CANT_ADD_PRODUCT_WITHOUT_PRICE: 'Выбран продукт без актуальной стоимости',
    },
  },
  DATES: {
    TODAY: 'Сегодня',
    MONTH: 'Месяц',
    YEAR: 'Год',
    YESTERDAY: 'Вчера',
    LAST_MONTH: 'Прошлый месяц',
    DAYS_OF_WEEK: {
      SU: 'Воскресенье',
      MO: 'Понедельник',
      TU: 'Вторник',
      WE: 'Среда',
      TH: 'Четверг',
      FR: 'Пятница',
      SA: 'Суббота',
    },
    DAYS_OF_WEEK_ARRAY: [
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
      'Воскресенье',
    ],
  },
  FORM: {
    LOCALE: 'Локаль',
    KEY: 'Ключ',
    TEXT: 'Текст',
    MAILER_FROM_EMAIL: 'Email адрес',
    PROLONGATE_SERVICE_PRICES_DAYS_BEFORE: 'Продление цены за X дней',
    PROLONGATE_SERVICE_PRICES_DAYS_COUNT: 'Продление цены на X дней',
    PROLONGATE_SERVICE_PRICES_DAYS_BEFORE_HINT: 'За сколько дней до окончания продлевать цены',
    PROLONGATE_SERVICE_PRICES_DAYS_COUNT_HINT: 'На сколько дней продлевать цены',
    ACTIVE_TELEGRAM_BOT: 'Активный телеграмм бот',
    CURRENCY_PROVIDERS: 'Источники курсов валют',
    TOKEN: 'Токен',
    SUBMIT: 'Подтвердить',
    CLEAR: 'Очистить',
    LOGO: 'Лого',
    AVATAR: 'Аватар',
    NAME: 'Имя',
    DOMAIN: 'Домен',
    PHONE: 'Телефон',
    DESCRIPTION: 'Описание',
    FIRST_NAME: 'Имя',
    LAST_NAME: 'Фамилия',
    EMAIL: 'E-mail',
    ROLE: 'Роль',
    COMPANY: 'Компания',
    PASSWORD: 'Пароль',
    PARENT: 'Родительская категория',
    SERVICE: 'Услуга',
    COST: 'Стоимость',
    START_DATE: 'Дата начала',
    USERNAME: 'Логин пользователя',
    END_DATE: 'Дата завершения',
    CATEGORY: 'Категория',
    CLIENT_SEARCH: 'Поиск по клиентам',
    NEXT: 'Далее',
    SAVE_AND_NEXT: 'Перейти далее',
    SUPPLIER: 'Поставщик',
    SKU: 'SKU',
    BRANCH: 'Филиал',
    COMMENT: 'Комментарий',
    ACTIVE: 'Активность',
    EMPLOYEES: 'Сотрудники',
    PERMISSIONS: 'Права',
    GROUPS: 'Группа прав',
    ENABLED: 'Активность',
    CLIENTS: 'Клиенты',
    CREATORS: 'Создатели',
    ADDRESS: 'Адрес',
    WORK_TIME_START: 'Начало работы',
    WORK_TIME_END: 'Конец работы',
    NOTIFY: 'Уведомлять когда заказ...',
  },
  PLACEHOLDER: {
    USERNAME: 'Введите логин',
    NAME: 'Введите название',
    DOMAIN: 'Введите домен',
    PHONE: 'Введите телефон',
    FIRST_NAME: 'Введите имя',
    LAST_NAME: 'Введите фамилию',
    EMAIL: 'Введите E-mail',
    ROLE: 'Выберете роль',
    COMPANY: 'Выберете компанию',
    PASSWORD: 'Введите пароль',
    DESCRIPTION: 'Введите описание',
    SELECT: 'Выберете',
    COST: 'Введите стоимость',
    QUANTITY: 'Введите количество',
    SKU: 'Введите SKU',
    COMMENT: 'Введите комментарий',
    ACTIVE: 'Активность',
    VALUE: 'Введите значение',
    CATEGORY: 'Категория',
    ADDRESS: 'Введите адрес',
    WORK_TIME_START: 'Начало работы',
    WORK_TIME_END: 'Конец работы',
    PERIODICITY: 'Введите периодичность',
    DISCOUNT: 'Введите скидку',
  },
  MODAL: {
    QR_CODE: {
      TITLE: 'Ссылка на Telegram-бота',
      COPY_LINK: 'Копировать ссылку',
      FOOTER: 'Передайте этот QR клиенту для того, чтобы он получал в Телеграм обновления статусов заказа и остальную информацию.',
      FOOTER_DANGER: 'Только для клиентов! Не используйте эту ссылку даже для тестирования.',
    },
    DELETE: {
      TITLE: 'Подтверждение',
      DESC: 'Вы уверены, что желаете удалить?',
    },
  },
  COMPANY: {
    TITLE: 'Список компаний',
    TITLE_NEW: 'Создание компании',
    TITLE_EDIT: 'Редактирование компании',
    RECEIPT_CONF: 'Конфигурация шаблона',
    NEW: 'Создать',
    COMPANIES: 'Компании',
    COMPANY_FIRST_OWNER: 'Собственник',
    COMPANY_CREDENTIALS: 'Реквизиты',
  },
  COMPANY_USER: {
    TITLE: 'Пользователи',
    TITLE_NEW: 'Создание пользователя',
    TITLE_EDIT: 'Редактирование пользователя',
    NEW: 'Создать',
    USERS: 'Пользователи',
    USER_CREDENTIALS: 'Реквизиты',
    ROLES: {
      ROLE_BUSINESS_ADMIN: 'Сотрудник компании',
      ROLE_BUSINESS_OWNER: 'Собственник компании',
    },
  },
  CLIENTS: {
    TITLE: 'Клиенты',
    TITLE_NEW: 'Создание клиента',
    TITLE_EDIT: 'Редактирование клиента',
    NAME: 'Имя',
    NEW: 'Создать',
    CREDENTIALS: 'Реквизиты',
    CREATED: 'Дата создания',
  },
  EMPLOYEES: {
    TITLE: 'Сотрудники',
    TITLE_NEW: 'Создание сотрудника',
    TITLE_EDIT: 'Редактирование сотрудника',
    NEW: 'Создать',
    USERS: 'Имя и дата регистрации',
    USER_CREDENTIALS: 'Реквизиты',
    ROLES: {
      ROLE_BUSINESS_ADMIN: 'Сотрудник компании',
      ROLE_BUSINESS_OWNER: 'Собственник компании',
    },
  },
  DASHBOARD: {
    LOGS_TITLE: 'Реестр действий компании',
    PLAN_CARD: {
      TITLE: 'Выполнение плана',
      SET_PLAN: 'Установить дневной план',
      PLAN_VALUE: 'Значение',
      OF: 'из',
    },
    ADMINS_STATISTICS: {
      TITLE: 'Статистика сотрудников',
      PROFILE: 'Профиль',
      ADMINS: 'Сотрудники',
      SERVICES_AND_PRODUCTS: 'Услуги и продукты',
      ORDER_QTY: 'Кол-во заказов',
      ORDER_WITH_DISCOUNT: 'Итоговая сумма',
    },
  },
  SERVICES_LIST: {
    TITLE: 'Услуги',
    TITLE_NEW: 'Создание услуги',
    TITLE_EDIT: 'Редактирование услуги',
    FORM_PRICE_TITLE: 'Стоимость услуги',
    NEW: 'Создать',
    NAME: 'Название',
    CATEGORY: 'Категория',
    TABLE_PRICE: 'История стоимости',
    COST: 'Стомость',
    NO_PRICE: 'нет стоимости',
    START_DATE: 'Дата начала',
    END_DATE: 'Дата завершения',
    PERIODICITY: 'Каждую',
    DISCOUNT_IS: 'Скидка',
    ITEM: 'услугу',
    DISCOUNTS: 'Скидки',
    DISCOUNT_HINT: 'Клиент получает скидку на каждую услугу кратную числу.',
    PERCENT_HINT: 'Определяет размер скидки.',
    REMOVE_DISCOUNT: 'Удалить скидку',
  },
  STOCK_ENTRY: {
    TITLE: 'Остатки товаров',
    PRODUCT: 'Товар',
    QUANTITY: 'Количество',
    WAREHOUSE: 'Склад',
    SOURCE: 'Источник',
    NEW: 'Создать',
    TITLE_NEW: 'Заведение товара',
    TITLE_EDIT: 'Редактирование',
    RATE: 'Цена',
    PRODUCT_FROM: 'Источник',
    SOURCE_SUPPLIER: 'Поставщик',
    SOURCE_WAREHOUSE: 'Склад',
  },
  PRODUCT_LIST: {
    TITLE: 'Товары',
    TITLE_NEW: 'Создание товара',
    TITLE_EDIT: 'Редактирование товара',
    FORM_PRICE_TITLE: 'Стоимость товара',
    NEW: 'Создать',
    NAME: 'Название',
    CATEGORY: 'Категория',
    TABLE_PRICE: 'История стоимости',
    COST: 'Стомость',
    NO_PRICE: 'нет стоимости',
    START_DATE: 'Дата начала',
    END_DATE: 'Дата завершения',
  },
  SERVICES_CATEGORY: {
    TITLE: 'Категории услуг',
    TITLE_NEW: 'Создание категории',
    TITLE_EDIT: 'Редактирование категории',
    NEW: 'Создать',
    NAME: 'Название',
    PARENT: 'Родительская категория',
  },
  SERVICES_PRICES: {
    TITLE: 'Services Prices',
    TITLE_NEW: 'Services Price New',
    TITLE_EDIT: 'Services Price Edit',
    NEW: 'New',
    COST: 'Стоимость',
    SERVICE: 'Услуга',
    START_DATE: 'Дата начала',
    END_DATE: 'Дата завершения',
  },
  PRODUCTS_LIST: {
    TITLE: 'Товары',
    TITLE_NEW: 'Создание товара',
    TITLE_EDIT: 'Редактирование товара',
    NEW: 'Создать',
    NAME: 'Название',
    SUPPLIER: 'Поставщик',
    CATEGORY: 'Категория',
  },
  PRODUCTS_CATEGORY: {
    TITLE: 'Категории продуктов',
    NAME: 'Название',
    PARENT: 'Родительская категория',
    NEW: 'Создать',
    TITLE_NEW: 'Создание категории',
    TITLE_EDIT: 'Редактирование категории',
  },
  PRODUCTS_SUPPLIER: {
    TITLE: 'Поставщики товаров',
    NAME: 'Название',
    NEW: 'Создать',
    TITLE_NEW: 'Создание поставщика',
    TITLE_EDIT: 'Редактирование поставщика',
  },
  BRANCHES: {
    TITLE: 'Филиалы',
    NAME: 'Название',
    NEW: 'Создать',
    TITLE_NEW: 'Создание филиала',
    TITLE_EDIT: 'Редактирование филиала',
    INFO: 'Эта информация используется в квитанциях.',
  },
  SETTINGS: {
    TITLE_EDIT: 'Изменить настройки',
  },
  TRANSLATIONS: {
    TITLE: 'Переводы',
    KEY: 'Ключ',
    LOCALE: 'Локаль',
    TEXT: 'Текст',
    NEW: 'Создать',
    TITLE_NEW: 'Создание перевода',
    TITLE_EDIT: 'Редактирование перевода',
  },
  BOT: {
    TOKEN: 'Токен',
    TITLE: 'Бот',
    NAME: 'Название',
    NEW: 'Создать',
    TITLE_NEW: 'Создание бота',
    TITLE_EDIT: 'Редактирование бота',
    ACTIVE_BOT_MISSING_ERR: 'Отсутствует активный бот.',
    IN_PROGRESS: 'В работе',
    COMPLETE: 'Готово',
    CANCEL: 'Отменено',
    STATUS_DESCRIPTION: 'Выбери статус, при котором бот будет уведомлять клиента.',
    STATUS_PLACEHOLDER: 'Выбери статус',
  },
  ORDERS: {
    TITLE: 'Заказы',
    TITLE_NEW: 'Форма заказа',
    NEW: 'Создать',
    NUMBER: 'Номер заказа',
    PHONE: 'Телефон',
    CLIENT_NAME: 'Имя клиента',
    TOTAL_COST: 'Сумма',
    FINAL_COST: 'Со скидками',
    STATUS: 'Статус',
    PREPAYMENT: 'Оплачено',
    CLIENT: {
      TITLE: 'Определение клиента заказа',
      CREATE_NEW_CLIENT: 'Добавить клиента',
    },
    ASIDE: {
      CLIENT: 'Определение клиента',
      CLIENT_DESC: 'Внесение информации о клиенте',
      SETUP: 'Формирование заказа',
      SETUP_DESC: 'Определение позиций заказа',
      FILES: 'Файлы',
      FILES_DESC: 'Загрузка файлов',
      SUMMARY: 'Итог заказа',
      SUMMARY_DESC: 'Проверка введенной информации',
    },
    DEADLINE: 'Дата готовности',
  },
  ORDER: {
    BACK_TO_LIST: 'Вернуться',
    TITLE_NEW: 'Создание заказа',
    TITLE_EDIT: 'Редактирование заказа',
    START_DATE: 'Дата создания',
    DUE_DATE: 'Нужно завершить',
    ITEMS: 'Предметов',
    SERVICES: 'Услуг',
    PRODUCTS: 'Продуктов',
    PREPAYMENT: 'Оплачено',
    RECEIPT: 'Квитанция',
    PRINT_ALL: 'Напечатать обе',
    PRINT_MAIN: 'Напечатать основную',
    PRINT_SECONDARY: 'Напечатать вторичную',
    SETUP: {
      NAME: 'Название',
      COST: 'Сумма',
      QUANTITY: 'Кол-во',
      DISCOUNTS: 'Скидки',
      TOTAL_COST: 'Сумма',
      TABLE_TITLE: 'Позиции заказа',
      ADD_SERVICE: 'Добавить услугу',
      ADD_PRODUCT: 'Добавить продукт',
      ITEMS: 'Предметы заказа',
      ITEM: 'Предмет заказа',
      OTHER_SERVICES_AND_PRODUCTS: 'Остальные услуги и продукты',
      ADD_ITEM: 'Добавить предмет',
      DEFAULT_ITEM: 'Предмет 1',
      ITEM_EDIT: 'Редактирование предмета',
      ITEM_NEW: 'Добавление предмета',
      TOTAL: 'Сумма',
      COST_WITH_DISCOUNT: 'Cо скидками',
      SERVICES_LIST: 'Список услуг',
      PRODUCTS_LIST: 'Список товаров',
    },
    DISCOUNT: {
      FORM_TITLE: 'Настройка скидки',
      TYPE: 'Тип',
      VALUE: 'Значение',
      ABSOLUTE: 'Абсолютная',
      PERCENT: 'Процентная',
    },
    FILES: {
      IMAGES: 'Изображения',
      DOCS: 'Документы',
    },
    STATUSES: {
      raw: 'Новый',
      in_progress: 'В работе',
      cancel: 'Отмененный',
      complete: 'Завершенный',
      archived: 'Архивированный',
    },
    PAYMENT_STATUSES: {
      paid: 'Оплачено',
      unpaid: 'Неоплачено',
      paid_partially: 'Оплачено частично',
    },
    SUMMARY: {
      TITLE: 'Просмотр и подтверждение заказа',
      CLIENT_INFO: 'О клиенте',
      NAME: 'Имя',
      PHONE: 'Телефон',
      ORDER_DETAILS: 'О составе заказа',
      SUMMARY: 'Summary',
      TOTAL_COST: 'Сумма',
      FINAL_COST: 'Cо скидками',
      COST: 'Сумма',
      DISCOUNTS: 'Скидки',
      QUANTITY: 'Кол-во',
      ORDER_WAREHOUSE: 'Состав заказа',
      COMMENT: 'Комментарий',
      ADD_DISCOUNT: 'Добавить скидку',
    },
  },
  ROLES_EMPLOYEES: {
    TITLE: 'Группы прав',
    NEW: 'Создать',
    NAME: 'Название группы',
    TITLE_NEW: 'Создание группы',
    TITLE_EDIT: 'Редактирование группы',
  },
  REPORTS: {
    NO_DATA: 'Данные не найдены. Настройте фильтры, чтобы уточнить результаты отчета.',
    TOTAL: 'Всего',
    FILTERS: {
      START_DATE: 'Дата от',
      END_DATE: 'Дата до',
      BRANCHES: 'Филиалы',
      CLIENTS: 'Клиенты',
      CREATORS: 'Создатели',
      SERVICES_CATEGORY: 'Категории услуг',
      SERVICES: 'Услуги',
      ORDER_STATUS: 'Статусы заказа',
      PAYMENT_STATUS: 'Статусы оплаты',
      DATE_STEP: {
        TITLE: 'Период',
        day: 'По дням',
        month: 'По месяцам',
        year: 'По годам',
      },
    },
    SERVICE_SUMS_BY_ORDERS: {
      TITLE: 'Анализ заказов (услуги)',
      DESCRIPTION: 'Информация о заказе: даты, клиент, кол-во предметов и услуг. Стоимости указаны с учетом скидок.',
      FINAL_COST: 'Сумма со скидками',
    },
    NUM_SERVICES_BY_CATEGORY: {
      TITLE: 'Аналитика продаж (услуги)',
      DESCRIPTION: 'Отчет по предоставленным услугам за день, месяц или год. Стоимости указаны с учетом скидок.',
    },
    NUM_SERVICES_BY_BRANCH: {
      TITLE: 'Аналитика по филиалам (услуги)',
      DESCRIPTION: 'Отчет по услугам, предоставленными по каждому филиалу. Стоимости указаны с учетом скидок.',
    },
    SUMS_BY_ORDERS: {
      TITLE: 'Общий отчет по продажам',
      DESCRIPTION: 'Стоимость и кол-во реализованных товаров и услуг. Стоимости указаны с и без учета скидок.',
    },
  },
  TRANSLATOR: {
    SELECT: 'Выберете язык интерфейса',
  },
  DATEPICKER: {
    NO_DATE_SELECTED: 'Дата не выбрана',
  },
  PAGE: {
    TRANSLATIONS: 'Переводы',
    COMMON: 'Общие',
    SETTINGS: 'Настройки',
    BOT: 'Бот',
    BOTS: 'Боты',
    COMPANIES: 'Компании',
    COMPANY_USERS: 'Пользователи',
    ECOMMERCE_SEPARATE: 'Коммерция',
    COMPANY_SEPARATE: 'Компания',
    STOCK_SEPARATE: 'Склад',
    COMPANY_USER: 'Пользователи',
    LAYOUT_BUILDER: 'Конструктор интерфейса',
    COMPANY: 'Компания',
    EMPLOYEES: 'Сотрудники',
    DASHBOARD: 'Панель показателей',
    ORDERS: 'Заказы',
    BRANCHES: 'Филиалы',
    CLIENTS: 'Клиенты',
    PRODUCTS_GROUP: 'Товары',
    REPORTS_GROUP: 'Отчеты',
    PRODUCTS_LIST: 'Список товаров',
    PRODUCTS_CATEGORIES: 'Категории товаров',
    PRODUCTS_SUPPLIER: 'Поставщики товаров',
    SERVICES_GROUP: 'Услуги',
    SERVICES_LIST: 'Список услуг',
    SERVICES_CATEGORIES: 'Категории услуг',
    SERVICES_PRICES: 'Цены',
    SERVICES_PRICES_EDIT: 'Редактрование цен',
    STAFF: 'Штат компании',
    ROLES_EMPLOYEES: 'Группы прав',
    ROLES_EMPLOYEES_NEW: 'Создание группы прав',
    ROLES_EMPLOYEES_EDIT: 'Редактрование группы прав',
    TEMPLATES_NEW: 'Новый шаблон',
    TEMPLATES: 'Шаблоны',
    WAREHOUSES: 'Склады',
    SUPPLIERS: 'Поставщики',
    STOCK_ENTRY: 'Запасы',
    BOT_EDIT: 'Редактрование бота',
    BOT_NEW: 'Новый бот',
  },
  AUTH: {
    SIGN_IN: 'Вход',
    SUBMIT: 'Подтвердить',
    FORGOT_PASS: 'Забыли пароль ?',
    FORGOT_PASS_DESC: 'Введите ниже свой E-mail для сброса пароля',
    GENERAL: {
      OR: 'Or',
      SUBMIT_BUTTON: 'Submit',
      NO_ACCOUNT: 'Don\'t have an account?',
      SIGNUP_BUTTON: 'Sign Up',
      FORGOT_BUTTON: 'Forgot Password',
      BACK_BUTTON: 'Back',
      PRIVACY: 'Privacy',
      LEGAL: 'Legal',
      CONTACT: 'Contact',
    },
    LOGIN: {
      TITLE: 'Login Account',
      DESC: 'Введите имя пользователя и пароль',
      BUTTON: 'Sign In',
    },
    LOGOUT: {
      BUTTON: 'Выход',
    },
    RESET: {
      TITLE: 'Восстановление пароля',
      DESC: 'Введите свой новый пароль',
      PASS_CHANGED: 'Пароль был успешно изменен',
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your E-mail to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
      REQUEST_SENT: 'Ссылка на восстановление пароля была отправлена на E-mail',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfully registered.',
    },
    VALIDATION: {
      INVALID: '{{name}} не является допустимым',
      REQUIRED: '{name} обязательное поле',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  TEMPLATES: {
    NEW: 'Создать',
    NAME: 'Название',
    VARIABLES: 'Значения',
    TITLE_NEW: 'Новый шаблон',
    ORDER_INVOICE_FOR_CUT: 'Шаблон вторичной квитанции',
    ORDER_INVOICE: 'Шаблон основной квитанции',
    PHONE_CONFIRMATION: 'Шаблон подтверждения телефона',
    ORDER_STATUS_IN_PROGRESS: 'Шаблон "Заказ в работе"',
    ORDER_STATUS_COMPLETE: 'Шаблон "Заказ выполнен"',
    ORDER_STATUS_CANCEL: 'Шаблон "Заказ отменен"',
  },
  STOCK: {
    TITLE: 'Склады',
    TITLE_EDIT: 'Редактирование склада',
    TITLE_NEW: 'Создание склада',
    DEFAULT: 'Склад по умолчанию',
    NEW: 'Новый склад',
    PARENT: 'Название родительского склада',
    NAME: 'Название',
  },
}
