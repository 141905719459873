<template>
<div class="modal-confirm">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <slot name="header" />
      </div>
      <div class="modal-body py-8" :class="bodyClass">
        <slot name="body" />
      </div>
      <div class="modal-footer py-3" v-if="!!$slots.footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ModalBase',
  props: {
    bodyClass: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
  .modal-dialog{
    width: 100%;
    max-width: inherit;
    height: 100%;
    max-height: inherit;
    margin: 0;
  }
</style>
