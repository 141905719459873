const stockEntry = [
  {
    path: '/stock-entry',
    name: 'companyStockEntryIndex',
    meta: {
      view: 'company',
      title: 'STOCK_ENTRY',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/stock-entry'),
  },
  {
    path: '/stock-entry/create',
    name: 'companyStockEntryCreate',
    meta: {
      view: 'company',
      title: 'STOCK_ENTRY_NEW',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/stock-entry/new'),
  },
  {
    path: '/stock-entry/:id',
    name: 'companyStockEntryEdit',
    meta: {
      view: 'company',
      title: 'STOCK_ENTRY_EDIT',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/stock-entry/id/edit'),
  },
]

export default stockEntry
