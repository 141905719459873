const ID_TOKEN_KEY = 'id_token'

export const getTokenData = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}
export const getAccessToken = () => {
  const tokenData = window.localStorage.getItem(ID_TOKEN_KEY)
  if (tokenData) {
    const { access_token: accessToken } = JSON.parse(tokenData)
    return accessToken
  }
  return ''
}
export const saveTokenData = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyTokenData = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export default {
  getTokenData,
  saveTokenData,
  destroyTokenData,
  getAccessToken, 
}
