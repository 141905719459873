<script>
import { isGranted } from '../../helpers/is-granted'

export default {
  name: 'IsGranted',
  functional: true,
  props: {
    attributes: {
      type: Array,
      default: () => [],
    },
  },
  render(h, { props, slots }) {
    if (isGranted(props.attributes)) {
      return slots().default
    }
    return h()
  },
}
</script>

<style scoped>

</style>
