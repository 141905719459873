// eslint-disable-next-line import/no-cycle
import ApiService from '../api.service'
// import router from './../../../router'
import { destroyTokenData, getTokenData, saveTokenData } from '../token.service'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const REFRESH_TOKEN = 'refreshToken'
export const CONFIRM_REGISTER = 'confirmRegister'
export const RESTORE_PASS_SEND_REQUEST = 'restorePassSendRequest'
export const RESTORE_PASS_RESET = 'restorePassReset'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const GET_USER = 'getUser'
export const UPDATE_USER = 'updateUser'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setAuth'
export const SET_USER = 'setUser'
export const SET_ERROR = 'setError'

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!getTokenData(),
}

const getters = {
  // eslint-disable-next-line no-shadow
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  // eslint-disable-next-line no-shadow
  getRole(state) {
    return state.user.role
  },
  getPermissions() {
    return state.user.permissions
  },
  // eslint-disable-next-line no-shadow
  getUser(state) {
    return state.user
  },
}

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      const oAuthRequired = {
        client_id: process.env.VUE_APP_OAUTH_V2_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_V2_CLIENT_SECRET,
        grant_type: 'password',
      }
      const postData = {
        ...oAuthRequired,
        ...credentials, 
      }
      ApiService.post('oauth/token', postData)
        .then(({ data }) => {
          console.log('payload', data)
          context.commit(SET_AUTH, data.payload)
          resolve(data.payload)
        })
        .catch((data) => {
          context.commit(SET_ERROR, data.response)
          reject(data)
        })
    })
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },
  [REFRESH_TOKEN]() {
    const tokenData = getTokenData()
    // eslint-disable-next-line camelcase
    const refresh_token = tokenData ? JSON.parse(tokenData).refresh_token : ''
    const postData = {
      client_id: process.env.VUE_APP_OAUTH_V2_CLIENT_ID,
      client_secret: process.env.VUE_APP_OAUTH_V2_CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token,
    }
    return ApiService.post('oauth/token', postData)
  },
  [CONFIRM_REGISTER](context, token) {
    return ApiService.post(`public/confirm-email/${token}`)
  },
  [RESTORE_PASS_SEND_REQUEST](context, payload) {
    return ApiService.post(`public/restore-pass/request/${process.env.VUE_APP_OAUTH_V2_CLIENT_ID}`, payload)
  },
  [RESTORE_PASS_RESET](context, payload) {
    const { token, ...rest } = payload
    return ApiService.post(`public/restore-pass/reset/${token}`, rest)
  },
  // eslint-disable-next-line consistent-return
  [VERIFY_AUTH](context) {
    if (getTokenData()) {
      if (!context.state.user.uuid) return context.dispatch(GET_USER)
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  [GET_USER](context) {
    return new Promise((resolve) => {
      ApiService.get('profile')
        .then(({ data }) => {
          context.commit(SET_USER, data.payload)
          resolve(data.payload)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  [SET_ERROR](state, error) {
    state.errors = error
  },
  // eslint-disable-next-line no-shadow
  [SET_USER](state, data) {
    state.user = data
  },
  // eslint-disable-next-line no-shadow
  [SET_AUTH](state, data) {
    state.isAuthenticated = true
    state.errors = {}
    saveTokenData(JSON.stringify(data))
  },
  // eslint-disable-next-line no-shadow
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    destroyTokenData()
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
