import Vue from 'vue'

export const VARIANT_WARNING = 'warning'
export const VARIANT_INFO = 'info'
export const VARIANT_SUCCESS = 'success'
export const VARIANT_DANGER = 'danger'
export const VARIANT_PRIMARY = 'primary'

Vue.mixin({
  methods: {
    $toastSuccess(message) {
      return () => this._toast(message, VARIANT_SUCCESS)
    },
    $toastWarning(message) {
      return () => this._toast(message, VARIANT_WARNING)
    },
    $toastInfo(message) {
      return () => this._toast(message, VARIANT_INFO)
    },
    $toastPrimary(message) {
      return () => this._toast(message, VARIANT_PRIMARY)
    },
    $toastDanger(message) {
      return () => this._toast(message, VARIANT_DANGER)
    },
    /**
         * @param message {String}
         * @param variant {String}
         * @param title {String}
         * @private
         */
    _toast(message, variant, title = '') {
      this.$bvToast.toast(this.$t(message), {
        title: this.$t(title || variant.toUpperCase()),
        variant,
        autoHideDelay: 2000,
        solid: false,
        headerClass: `b-toast-${variant}`,
        bodyClass: `b-toast-${variant}`,
        noFade: true,
      })
    },
  },
})
