import company from './company'
import companyUser from './company-user'
import templates from './templates'

const routers = [
  {
    path: '/',
    name: 'dashboardAdmin',
    meta: {
      view: 'admin',
      title: 'DASHBOARD', 
    },
    component: () => import('@/view/pages/admin/Dashboard.vue'),
  },
  {
    path: '/admin/builder',
    name: 'builderAdmin',
    meta: { view: 'admin' },
    component: () => import('@/view/pages/admin/Builder.vue'),
  },
  ...company,
  ...companyUser,
  ...templates,
]
export default routers
