export default [
  {
    path: '/',
    component: () => import('@/view/pages/auth/Auth'),
    children: [
      {
        name: 'login',
        path: '/login',
        meta: { title: 'LOGIN' },
        component: () => import('@/view/pages/auth/Login'),
      },
      {
        name: 'register',
        path: '/register',
        meta: { title: 'REGISTER' },
        component: () => import('@/view/pages/auth/Register'),
      },
      {
        name: 'forgotPass',
        path: '/forgot-pass',
        meta: { title: 'FORGOT_PASS' },
        component: () => import('@/view/pages/auth/ForgotPass'),
      },
      {
        name: 'confirmRegister',
        path: '/registration-confirm',
        meta: { title: 'CONFIRM_REGISTER' },
        component: () => import('@/view/pages/auth/ConfirmRegister'),
      },
      {
        name: 'resetPass',
        path: '/reset-password',
        meta: { title: 'RESET_PASS' },
        component: () => import('@/view/pages/auth/ResetPass'),
      },
    ],
  },
]
