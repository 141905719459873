import axios from 'axios'
import { prepareParams, paramsSerializerQs } from '@/helpers/api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { getTokenData } from './token.service'
// eslint-disable-next-line import/no-cycle
import store from './store'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { PURGE_AUTH, REFRESH_TOKEN, SET_AUTH } from './store/auth.module'
import i18nService from './i18n.service'

const ApiService = {
  constructor() {
    this.instance = null
  },
  init() {
    this.instance = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND_ORIGIN}/api/v1`,
    })
    this.instance.interceptors.request.use(
      (config) => {
        config.headers['Accept-Language'] = i18nService.getActiveLanguage()
        const tokenData = getTokenData()
        if (tokenData) {
          const { access_token: accessToken } = JSON.parse(tokenData)
          if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
          return config
        } return config
      },
      (error) => Promise.reject(error),
    )
    let isRefreshing = false
    let subscribers = []
    const subscribeTokenRefresh = (cb) => subscribers.push(cb)
    const onRefreshed = (token) => subscribers.map((cb) => cb(token))
    this.instance.interceptors.response.use(undefined, (err) => {
      const config = err.config
      const status = err.response ? err.response.status : null
      const originalRequest = config
      store.commit('setErrorRequest', err.response)
      if (status === 401) {
        if (!isRefreshing) {
          isRefreshing = true
          store.dispatch(REFRESH_TOKEN)
            .then(({ data }) => {
              isRefreshing = false
              onRefreshed(data.payload.access_token)
              store.commit(SET_AUTH, data.payload)
              subscribers = []
            })
            .catch(() => {
              store.commit(PURGE_AUTH)
              router.push('login')
            })
        }
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(axios(originalRequest))
          })
        })
      }
      return Promise.reject(err)
    })
  },
  request(url, params, cfg) {
    const config = {
      params: prepareParams(params),
      paramsSerializer: paramsSerializerQs,
      ...cfg,
    }
    return this.instance.get(url, config)
  },
  get(url, params) {
    const config = {
      params: prepareParams(params),
      paramsSerializer: paramsSerializerQs,
    }
    return this.instance.get(url, config)
  },
  postFile(url, data, params = {}) {
    const config = {
      ...params,
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    return this.instance.post(url, data, config)
  },
  post(url, params) {
    return this.instance.post(`${url}`, params)
  },
  update(url, slug, params) {
    return this.instance.put(`${url}/${slug}`, params)
  },
  put(url, params) {
    return this.instance.put(`${url}`, params)
  },
  patch(url, params) {
    return this.instance.patch(`${url}`, params)
  },
  delete(url, data) {
    return this.instance.delete(url, { ...data }).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  },
}
export default ApiService
