import Vue from 'vue'
import Vuex from 'vuex'

// eslint-disable-next-line import/no-cycle
import auth from './auth.module'
import htmlClass from './htmlclass.module'
import scroll from './scroll.module'
import config from './config.module'
import breadcrumbs from './breadcrumbs.module'
import order from './order.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    order,
    scroll,
  },
})
