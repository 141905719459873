const employees = [
  {
    path: '/staff/employees',
    name: 'businessUser',
    meta: {
      view: 'company',
      title: 'EMPLOYEES',
      permission: ['view_employees'], 
    },
    component: () => import('@/view/pages/company/employees'),
  },
  {
    path: '/staff/employees/create',
    name: 'createBusinessUser',
    meta: {
      view: 'company',
      title: 'EMPLOYEES_NEW',
      permission: ['edit_employees'], 
    },
    component: () => import('@/view/pages/company/employees/new'),
  },
  {
    path: '/staff/employees/:id',
    name: 'editBusinessUser',
    meta: {
      view: 'company',
      title: 'EMPLOYEES_EDIT',
      permission: ['edit_employees'], 
    },
    component: () => import('@/view/pages/company/employees/id/edit'),
  },
]
export default employees
