// action types
export const APPEND_BREADCRUMB = 'appendBreadcrumb'

// mutation types
export const SET_BREADCRUMB = 'setBreadcrumb'
export const ADD_BREADCRUMB = 'addBreadcrumb'

export default {
  state: {
    breadcrumbs: [],
  },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    breadcrumbs(state) {
      return state.breadcrumbs
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    // eslint-disable-next-line consistent-return
    pageTitle(state) {
      const last = state.breadcrumbs[state.breadcrumbs.length - 1]
      if (last && last.title) {
        return last.title
      }
    },
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [SET_BREADCRUMB](state, payload) {
      state.commit(SET_BREADCRUMB, payload)
    },

    /**
     * Add breadcrumb
     * @param state
     * @param payload
     */
    [ADD_BREADCRUMB](state, payload) {
      if (typeof payload === 'object') {
        payload.forEach((item) => state.commit(APPEND_BREADCRUMB, item))
      } else {
        state.commit(APPEND_BREADCRUMB, payload)
      }
    },
  },
  mutations: {
    [APPEND_BREADCRUMB](state, payload) {
      state.breadcrumbs = [...state.breadcrumbs, payload]
    },
    [SET_BREADCRUMB](state, payload) {
      state.breadcrumbs = payload
    },
  },
}
