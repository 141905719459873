import Vue from 'vue'
import store from '@/core/services/store'

Vue.directive('permission', {
  inserted: (el, binding) => {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  },
})
function checkPermission(el, binding) {
  const { value: permissionRoles } = binding
  const permissions = store.getters.getPermissions

  if (permissionRoles?.length) {
    const hasPermission = permissions
      .map((m) => m.attribute)
      .some((role) => permissionRoles.includes(role))
    // eslint-disable-next-line no-unused-expressions
    if (!hasPermission) el?.parentNode?.removeChild(el)
  } else {
    throw new Error('need roles!')
  }
}
