const settings = [
  {
    path: '/settings',
    name: 'companySettingsEdit',
    meta: {
      view: 'company',
      title: 'SETTINGS',
    },
    component: () => import('@/view/pages/company/settings/edit'),
  },
]
export default settings
