const templates = [
  {
    path: '/admin/company/:id/templates/create',
    name: 'adminTemplatesCreate',
    meta: {
      view: 'admin',
      title: 'TEMPLATES_NEW', 
    },
    component: () => import('@/view/pages/admin/company/id/templates/new'),
  },
  {
    path: '/admin/company/:id/templates/:template_id',
    name: 'adminTemplatesEdit',
    meta: {
      view: 'admin',
      title: 'TEMPLATES_EDIT', 
    },
    component: () => import('@/view/pages/admin/company/id/templates/template_id/edit'),
  },
]
export default templates
