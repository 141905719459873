const roles = [
  {
    path: '/staff/roles',
    name: 'roleBusinessUser',
    meta: {
      view: 'company',
      title: 'ROLES_EMPLOYEES',
      permission: ['view_groups'], 
    },
    component: () => import('@/view/pages/company/roles'),
  },
  {
    path: '/staff/roles/create',
    name: 'createRoleBusinessUser',
    meta: {
      view: 'company',
      title: 'ROLES_EMPLOYEES_NEW',
      permission: ['edit_groups'], 
    },
    component: () => import('@/view/pages/company/roles/new'),
  },
  {
    path: '/staff/roles/:id',
    name: 'editRoleBusinessUser',
    meta: {
      view: 'company',
      title: 'ROLES_EMPLOYEES_EDIT',
      permission: ['edit_groups'], 
    },
    component: () => import('@/view/pages/company/roles/id/edit'),
  },
]
export default roles
