const translations = [
  {
    path: '/translations',
    name: 'companyTranslationsIndex',
    meta: {
      view: 'company',
      title: 'TRANSLATIONS',
      permission: ['view_translations'],
    },
    component: () => import('@/view/pages/company/translations/index'),
  },
  {
    path: '/translations/create',
    name: 'companyTranslationsCreate',
    meta: {
      view: 'company',
      title: 'TRANSLATIONS_NEW',
      permission: ['edit_translations'], 
    },
    component: () => import('@/view/pages/company/translations/new'),
  },
  {
    path: '/translations/:id',
    name: 'companyTranslationsEdit',
    meta: {
      view: 'company',
      title: 'TRANSLATIONS_EDIT',
      permission: ['edit_translations'], 
    },
    component: () => import('@/view/pages/company/translations/id/edit'),
  },
]
export default translations
