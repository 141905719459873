const warehouses = [
  {
    path: '/warehouses',
    name: 'companyWarehousesIndex',
    meta: {
      view: 'company',
      title: 'WAREHOUSES',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/warehouses'),
  },
  {
    path: '/warehouses/create',
    name: 'companyWarehousesCreate',
    meta: {
      view: 'company',
      title: 'WAREHOUSES_NEW',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/warehouses/new'),
  },
  {
    path: '/warehouses/:id',
    name: 'companyWarehousesEdit',
    meta: {
      view: 'company',
      title: 'WAREHOUSES_EDIT',
      permission: ['view_branches'],
    },
    component: () => import('@/view/pages/company/warehouses/id/edit'),
  },
]

export default warehouses
