const bot = [
  {
    path: '/bot',
    name: 'companyBotIndex',
    meta: {
      view: 'company',
      title: 'BOT',
      permission: ['view_bot'], 
    },
    component: () => import('@/view/pages/company/bot'),
  },
  {
    path: '/bot/create',
    name: 'companyBotCreate',
    meta: {
      view: 'company',
      title: 'BOT_NEW',
      permission: ['edit_bot'], 
    },
    component: () => import('@/view/pages/company/bot/new'),
  },
  {
    path: '/bot/:id',
    name: 'companyBotEdit',
    meta: {
      view: 'company',
      title: 'BOT_EDIT',
      permission: ['edit_bot'], 
    },
    component: () => import('@/view/pages/company/bot/id/edit'),
  },
]
export default bot
