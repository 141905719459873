// action types
export const SAVE_SCROLL_POSITION = 'saveScrollPosition'
export const SCROLL_TO_POSITION = 'scrollToPosition'

// mutations
export const SET_SCROLL_POSITION = 'setScrollPosition'

export default {
  state: {
    savedPosition: 0,
  },
  getters: {
    getSavedPosition: (state) => state.savedPosition,
  },
  actions: {
    [SAVE_SCROLL_POSITION](context) {
      context.commit(SET_SCROLL_POSITION, document.documentElement.scrollTop)
    },
    [SCROLL_TO_POSITION]({ getters }) {
      window.window.scrollTo(0, getters.getSavedPosition)
    },
  },
  mutations: {
    [SET_SCROLL_POSITION](state, payload) {
      state.savedPosition = payload
    },
  },
}
