export default [
  {
    path: '/admin/company',
    name: 'company',
    meta: {
      view: 'admin',
      title: 'COMPANIES', 
    },
    component: () => import('@/view/pages/admin/company'),
  },
  {
    path: '/admin/company/create',
    name: 'createCompany',
    meta: {
      view: 'admin',
      title: 'COMPANIES_NEW', 
    },
    component: () => import('@/view/pages/admin/company/new'),
  },
  {
    path: '/admin/company/:id',
    name: 'editCompany',
    meta: {
      view: 'admin',
      title: 'COMPANIES_EDIT', 
    },
    component: () => import('@/view/pages/admin/company/id/edit'),
  },
]
