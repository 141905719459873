import Vue from 'vue'
import VModal from 'vue-js-modal'
import ModalConfirm from '@/components/modals/ModalConfirm'

Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true, 
})

Vue.prototype.$confirmAction = () => {
  return new Promise((resolve, reject) => {
    Vue.prototype.$modal.show(ModalConfirm, {
      handlers: {
        onConfirm: resolve,
        closed: reject,
      },
    }, {
      height: 'auto',
      adaptive: true,
      clickToClose: false, 
    }, { closed: reject })
  })
}
