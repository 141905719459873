export default [
  {
    path: '/admin/company-user',
    name: 'companyUser',
    meta: {
      view: 'admin',
      title: 'COMPANY_USERS', 
    },
    component: () => import('@/view/pages/admin/company-user'),
  },
  {
    path: '/admin/company-user/create',
    name: 'createCompanyUser',
    meta: {
      view: 'admin',
      title: 'COMPANY_USER_NEW', 
    },
    component: () => import('@/view/pages/admin/company-user/new'),
  },
  {
    path: '/admin/company-user/:id',
    name: 'editCompanyUser',
    meta: {
      view: 'admin',
      title: 'COMPANY_USER_EDIT', 
    },
    component: () => import('@/view/pages/admin/company-user/id/edit'),
  },
]
