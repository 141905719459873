const templates = [
  {
    path: '/templates',
    name: 'companyTemplatesIndex',
    meta: {
      view: 'company',
      title: 'TEMPLATES',
      permission: ['view_templates'], 
    },
    component: () => import('@/view/pages/company/templates'),
  },
  {
    path: '/templates/create',
    name: 'companyTemplatesCreate',
    meta: {
      view: 'company',
      title: 'TEMPLATES_NEW',
      permission: ['edit_templates'], 
    },
    component: () => import('@/view/pages/company/templates/new'),
  },
  {
    path: '/templates/:id',
    name: 'companyTemplatesEdit',
    meta: {
      view: 'company',
      title: 'TEMPLATES_EDIT',
      permission: ['edit_templates'], 
    },
    component: () => import('@/view/pages/company/templates/id/edit'),
  },
]
export default templates
