import Vue from 'vue'

Vue.filter('centsToDollars', (num) => {
  if (num) {
    const dollars = num / 100
    const currencyOptions = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).resolvedOptions()

    const value = (dollars).toLocaleString('en-US', {
      ...currencyOptions,
      style: 'decimal',
    })
    return value
  } return num
})
