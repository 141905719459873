const services = [
  {
    path: '/services',
    name: 'services',
    meta: { view: 'company' },
    component: () => import('@/view/pages/company/services/'),
    children: [
      {
        path: '/services/list',
        name: 'servicesListIndex',
        meta: {
          view: 'company',
          title: 'SERVICES_LIST',
          permission: ['view_services'], 
        },
        component: () => import('@/view/pages/company/services/list'),
      },
      {
        path: '/services/list/create',
        name: 'servicesListCreate',
        meta: {
          view: 'company',
          title: 'SERVICES_LIST_NEW',
          permission: ['edit_services'], 
        },
        component: () => import('@/view/pages/company/services/list/new'),
      },
      {
        path: '/services/list/:id',
        name: 'servicesListEdit',
        meta: {
          view: 'company',
          title: 'SERVICES_LIST_EDIT',
          permission: ['edit_services'], 
        },
        component: () => import('@/view/pages/company/services/list/id/edit'),
      },
      {
        path: '/services/categories',
        name: 'servicesCategoriesIndex',
        meta: {
          view: 'company',
          title: 'SERVICES_CATEGORIES',
          permission: ['view_services'], 
        },
        component: () => import('@/view/pages/company/services/categories'),
      },
      {
        path: '/services/categories/create',
        name: 'servicesCategoriesCreate',
        meta: {
          view: 'company',
          title: 'SERVICES_CATEGORIES_NEW',
          permission: ['edit_services'], 
        },
        component: () => import('@/view/pages/company/services/categories/new'),
      },
      {
        path: '/services/categories/:id',
        name: 'servicesCategoriesEdit',
        meta: {
          view: 'company',
          title: 'SERVICES_CATEGORIES_EDIT',
          permission: ['edit_services'], 
        },
        component: () => import('@/view/pages/company/services/categories/id/edit'),
      },
      {
        path: '/services/prices',
        name: 'servicesPricesIndex',
        meta: {
          view: 'company',
          title: 'SERVICES_PRICES',
          permission: ['view_services'], 
        },
        component: () => import('@/view/pages/company/services/prices'),
      },
      {
        path: '/services/prices/create',
        name: 'servicesPricesCreate',
        meta: {
          view: 'company',
          title: 'SERVICES_PRICES_NEW',
          permission: ['edit_services'], 
        },
        component: () => import('@/view/pages/company/services/prices/new'),
      },
      {
        path: '/services/prices/:id',
        name: 'servicesPricesEdit',
        meta: {
          view: 'company',
          title: 'SERVICES_PRICES_EDIT',
          permission: ['edit_services'], 
        },
        component: () => import('@/view/pages/company/services/prices/id/edit'),
      },
    ],
  },
]
export default services
