import qs from 'qs'

function convertBooleanToInt(prefix, value) {
  if (typeof value === 'boolean') {
    return +value
  }
  return value
}
export function paramsSerializerQs(params) {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
    filter: convertBooleanToInt,
  })
}
export function paramsSerializer(params) {
  return JSON.stringify(params, {
    filter: convertBooleanToInt,
  })
}

export function prepareParams(params) {
  if (typeof params === 'object' && !Array.isArray(params) && params !== null) {
    const paramsKeys = Object.keys(params)

    return paramsKeys.reduce((acc, key) => {
      if (typeof params[key] === 'string' && params[key]?.length === 0) return acc

      acc[key] = params[key]

      return acc
    }, {})
  }

  return params
}
