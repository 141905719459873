// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import RepoOrder from '@/core/repository/company/ordersRepository'

export const FETCH_ORDER = 'fetchOrder'
export const GET_ORDER = 'getOrder'
export const GET_ORDER_WARNINGS = 'getOrderWarnings'
export const UPDATE_ORDER = 'updateOrder'
export const SET_ORDER = 'setOrder'
export const CREATE_ORDER = 'createOrder'
export const SET_ORDER_VALID = 'setOrderValid'
export const SET_ORDER_WARNINGS = 'setOrderWarnings'

const state = {
  item: {},
  isValid: true,
  orderWarnings: { test1: 'state' },
}

const getters = {
  // eslint-disable-next-line no-shadow
  [GET_ORDER](state) {
    return state.item
  },
  // eslint-disable-next-line no-shadow
  [GET_ORDER_WARNINGS](state) {
    return state.orderWarnings
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  [SET_ORDER](state, data) {
    state.item = data
  },
  // eslint-disable-next-line no-shadow
  [SET_ORDER_VALID](state, data) {
    state.isValid = data
  },
  // eslint-disable-next-line no-shadow
  [SET_ORDER_WARNINGS](state, data) {
    state.orderWarnings = data
  },
}

const actions = {
  [FETCH_ORDER](context, uuid) {
    return new Promise((resolve) => {
      RepoOrder.get(uuid)
        .then(({ data }) => {
          context.commit(SET_ORDER, data.payload)

          context.commit(SET_ORDER_WARNINGS, data.warnings)
          resolve(data.payload)
        })
    })
  },
  async [UPDATE_ORDER](context, payload) {
    const { uuid, ...rest } = payload
    return RepoOrder.patch(uuid, rest)
  },
  async [CREATE_ORDER](context, payload) {
    return RepoOrder.post(payload)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
