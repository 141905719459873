import SuperRepository from '../superRepository'

export default new class OrdersRepository extends SuperRepository {
  baseUri() {
    return 'company/orders'
  }

  printReceipt = async (uuid, params, config) => this.httpClient().request(`${this.baseUri()}/${uuid}/invoice`, params, {
    ...config,
    responseType: 'arraybuffer',
  });

  printCutReceipt = async (uuid, params, config) => this.httpClient().request(`${this.baseUri()}/${uuid}/invoice-for-cut`, params, {
    ...config,
    responseType: 'arraybuffer',
  });

  printMergedReceipt = async (uuid, params, config) => this.httpClient().request(`${this.baseUri()}/${uuid}/invoice-merged`, params, {
    ...config,
    responseType: 'arraybuffer',
  });
}()
