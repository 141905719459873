const reports = [
  {
    path: '/reports',
    name: 'companyReportIndex',
    meta: {
      view: 'company',
      title: 'REPORTS_GROUP',
      permission: ['view_service_category_sums_by_orders', 'view_num_services_by_category', 'view_num_services_by_branch', 'view_sums_by_orders'],
    },
    component: () => import('@/view/pages/company/reports'),
  },
  {
    path: '/reports/service-category-sums-by-orders',
    name: 'serviceCategorySumsByOrders',
    meta: {
      view: 'company',
      title: 'REPORTS_GROUP',
      permission: ['view_service_category_sums_by_orders'], 
    },
    component: () => import('@/view/pages/company/reports/serviceCategorySumsByOrders'),
  },
  {
    path: '/reports/num-services-by-category',
    name: 'numServicesByCategory',
    meta: {
      view: 'company',
      title: 'REPORTS_GROUP',
      permission: ['view_num_services_by_category'], 
    },
    component: () => import('@/view/pages/company/reports/numServicesByCategory'),
  },
  {
    path: '/reports/num-services-by-branch',
    name: 'numServicesByBranch',
    meta: {
      view: 'company',
      title: 'REPORTS_GROUP',
      permission: ['view_num_services_by_branch'], 
    },
    component: () => import('@/view/pages/company/reports/numServicesByBranch'),
  },
  {
    path: '/reports/sums-by-orders',
    name: 'sumsByOrders',
    meta: {
      view: 'company',
      title: 'REPORTS_GROUP',
      permission: ['view_sums_by_orders'], 
    },
    component: () => import('@/view/pages/company/reports/sumsByOrders'),
  },
]
export default reports
