const clients = [
  {
    path: '/clients',
    name: 'companyClientsIndex',
    meta: {
      view: 'company',
      title: 'CLIENTS',
      permission: ['view_clients'], 
    },
    component: () => import('@/view/pages/company/clients'),
  },
  {
    path: '/clients/create',
    name: 'companyClientsCreate',
    meta: {
      view: 'company',
      title: 'CLIENTS_NEW',
      permission: ['edit_clients'], 
    },
    component: () => import('@/view/pages/company/clients/new'),
  },
  {
    path: '/clients/:id',
    name: 'companyClientsEdit',
    meta: {
      view: 'company',
      title: 'CLIENTS_EDIT',
      permission: ['edit_clients'], 
    },
    component: () => import('@/view/pages/company/clients/id/edit'),
  },
]
export default clients
