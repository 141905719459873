const orders = [
  {
    path: '/orders',
    name: 'companyOrdersIndex',
    meta: {
      view: 'company',
      title: 'ORDERS',
      permission: ['view_orders'], 
    },
    component: () => import('@/view/pages/company/orders'),
  },
  {
    path: '/orders/create',
    name: 'companyOrdersCreate',
    meta: {
      view: 'company',
      title: 'ORDERS_NEW',
      permission: ['edit_orders'], 
    },
    component: () => import('@/view/pages/company/orders/new'),
  },
  {
    path: '/orders/:id',
    name: 'companyOrdersEdit',
    meta: {
      view: 'company',
      title: 'ORDERS_EDIT',
      permission: ['edit_orders'], 
    },
    component: () => import('@/view/pages/company/orders/id/edit'),
  },
]
export default orders
