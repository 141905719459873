<template>
<ModalBase>
  <template #header>
    <h5 class="modal-title" id="exampleModalLabel">{{ $t('MODAL.DELETE.TITLE') }}</h5>
    <div class="btn btn-icon" @click="$emit('close')">
      <span class="svg-icon svg-icon-secondary svg-icon-lg">
        <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
      </span>
    </div>
  </template>
  <template #body>
    {{ $t('MODAL.DELETE.DESC') }}
  </template>
  <template #footer>
    <button type="button" class="btn btn-secondary font-weight-bold" @click="$emit('close')">
      <span class="svg-icon">
        <inline-svg src="/media/svg/icons/General/Smile.svg" />
      </span>
      {{ $t('BASE.NO') }}
    </button>
    <button type="button" class="btn btn-danger font-weight-bold" @click="onConfirm">
      <span class="svg-icon">
        <inline-svg src="/media/svg/icons/General/Sad.svg" />
      </span>
      {{ $t('BASE.YES') }}
    </button>
  </template>
</ModalBase>
</template>

<script>
import ModalBase from './ModalBase'

export default {
  name: 'ModalConfirm',
  components: { ModalBase },
  props: {
    handlers: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onConfirm() {
      this.handlers.onConfirm()
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
