import Vue from 'vue'
import Router from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/core/services/store'
// eslint-disable-next-line import/no-cycle
import { VERIFY_AUTH } from '@/core/services/store/auth.module'
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module'
import i18n from '@/core/plugins/vue-i18n'
import adminRouters from './routers/admin'
import companyRouters from './routers/company'
import clientRouters from './routers/client'
import error from './routers/error'
import auth from './routers/auth'
// eslint-disable-next-line import/no-cycle
import { isGranted } from './helpers/is-granted'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/view/layout/LayoutMain'),
      children: [
        ...adminRouters,
        ...companyRouters,
        ...clientRouters,
      ],
    },
    ...error,
    ...auth,
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      meta: {
        title: 'ERROR',
      },
      component: () => import('@/view/pages/error/Error-404.vue'),
    },
    {
      // the 404 route, when none of the above matches
      path: '/403',
      name: '403',
      meta: {
        title: 'ERROR',
      },
      component: () => import('@/view/pages/error/Error-403.vue'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise
    .all([store.dispatch(VERIFY_AUTH)])
    .then(() => {
      if (to.meta.hasOwnProperty('permission') && store.getters.isAuthenticated && !isGranted(to.meta.permission)) {
        next('403')
      } else {
        next()
      }
    })

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG)

  // set page tab name
  document.title = i18n.t(`PAGE.${to.meta.title}`)
  // Scroll page to top on every route change
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 100);
})

export default router
